import React from "react";
import { sitemap } from "../../data/sitemap";
import { Layout } from "../../components/layout/Layout";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import CookieBanner from "../../components/cookie/CookieBanner";
import { SitemapPage } from "../../components/helmet/ReactHelmet";
function Sitemap() {
  return (
    <div className="bg-bgWhite h-screen font-poppins">
      <SitemapPage />
      <Navbar />
      <CookieBanner />
      <div className="pt-4 sm:pt-6 md:pt-16 lg:pt-12 xl:pt-16 bg-bgWhite ">
        <Layout>
          <>
            <h1 className="font-arimo text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold">
              Sitemap
            </h1>
            <ul className="grid grid-cols-1 mt-4 md:mt-6 xl:mt-8 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {sitemap.map((item, index) => (
                <li
                  key={index}
                  className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition duration-300"
                >
                  <a
                    href={item.link}
                    className="text-lg font-medium text-gray-700 hover:text-blue-500"
                  >
                    {item.text}
                  </a>
                </li>
              ))}
            </ul>
          </>
        </Layout>
      </div>
      <div className="mt-12 md:mt-16 lg:mt-24 bg-bgWhite"></div>
      <Footer />
    </div>
  );
}

export default Sitemap;
