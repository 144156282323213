import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import { Layout } from "../../components/layout/Layout";
import { gallery } from "../../data/gallery";
import FeaturedImages from "./FeaturedImages";
import Footer from "../../components/footer/Footer";
import Cta from "./Cta";
import EventGallery from "./EventGallery";
import ProjectHighlights from "./ProjectHighlights";
import Facilities from "./Facilties";
import Team from "./Team";
import AOS from "aos";
import { component } from "../../data/component";
import CookieBanner from "../../components/cookie/CookieBanner";
import { GalleryPage } from "../../components/helmet/ReactHelmet";
function Gallery() {
  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration
      once: true, // Animate only once
    });
  }, []);
  return (
    <div className="bg-bgWhite">
      <GalleryPage />
      <Navbar />
      <CookieBanner />
      {gallery.map((src) => (
        <div className="pt-4 sm:pt-6 md:pt-16 lg:pt-12 xl:pt-16 bg-bgWhite">
          <Layout>
            <h1
              data-aos="fade-up"
              className="font-arimo text-3xl sm:text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl font-semibold"
            >
              {src.head}
            </h1>
            <div className="flex flex-col lg:flex-row ">
              <div className="lg:w-3/6"></div>
              <p
                data-aos="fade-up"
                data-aos-delay="500"
                className="mt-3 md:mt-5 xl:mt-8 lg:w-2/6 self-end"
              >
                {src.desc}
              </p>
            </div>
            <div className="mt-8 md:mt-12 lg:mt-16">
              <span
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-delay="200"
                className="  justify-center flex text-center mx-auto text-4xl sm:text-7xl xl:text-9xl font-bold text-transparent"
                style={{
                  WebkitTextStroke: "1px #7D6D6D", // Outline color and width
                }}
              >
                UNVEILING OUR <br />
                VISUAL JOURNEY
              </span>
              <div>
                <img
                  data-aos="zoom-in-right"
                  data-aos-delay="200"
                  src={src.img}
                  alt=""
                  className="rounded-[32px] -mt-2 lg:-mt-8 drop-shadow-xl"
                />
              </div>
            </div>
          </Layout>

          <FeaturedImages />
          <div className="mt-8 md:mt-12 lg:mt-16">
            <Cta />
          </div>
          <EventGallery />
          <Team />
          <ProjectHighlights />
          <Facilities />
          <div className="mt-12 md:mt-16 lg:mt-24"></div>
          <Footer />
        </div>
      ))}
    </div>
  );
}

export default Gallery;
