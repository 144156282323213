import React from "react";
import { LayoutCommon } from "../../components/layout/Layout";
import { directors } from "../../data/about";
import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
function Directors() {
  return (
    <div className="bg-bgWhite pb-5">
      <LayoutCommon>
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold">
          Meet Our Directors{" "}
        </h2>
        <p className="mt-3 lg:mt-4 md:w-4/6">
          Our journey is guided by a team of experienced directors who bring a
          wealth of knowledge and a shared vision of excellence
        </p>
        {/* Desktop view */}
        <div className="block">
          <div className="mt-3 md:mt-5 grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-8">
            {directors.map((director, index) => (
              <div
                key={index}
                className="relative overflow-hidden rounded-tl-[18px] rounded-bl-[18px] rounded-br-[18px] rounded-tr-[30px] sm:rounded-tr-[80px]"
              >
                {/* Image Container with overlay animation */}
                <div className="relative w-full overflow-hidden group rounded-tl-[18px] rounded-bl-[18px] rounded-br-[18px] rounded-tr-[30px] sm:rounded-tr-[80px]">
                  <img
                    src={director.image}
                    alt={director.alt}
                    className="w-full h-auto object-cover"
                  />

                  {/* Overlay with animated slide-up effect */}
                  <div className="absolute inset-0 bg-gradient-to-t from-[#000000e6] via-[#060A2Ccc] to-[#060A2C99] translate-y-full group-hover:translate-y-0 transition-transform duration-500 ease-in-out sm:flex items-center justify-center px-4 text-center rounded-tl-[18px] rounded-bl-[18px] rounded-br-[18px] rounded-tr-[50px] sm:rounded-tr-[80px] hidden">
                    <p className="text-white text-sm sm:text-md font-medium">
                      {director.desc}
                    </p>
                  </div>
                </div>

                {/* Director's details below the image */}
                <div className="text-center mt-4 self-start">
                  <h5 className="text-base sm:text-lg md:text-xl lg:text-2xl 2xl:text-3xl font-semibold text-black">
                    {director.name}
                  </h5>
                  <p className="text-peach font-regular text-sm md:text-base lg:text-lg">
                    {director.position}
                  </p>

                  {/* Social media icons with hover effects */}
                  <div className="flex justify-center gap-1 mt-1">
                    <a
                      href={director.Instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black transition-transform duration-300 transform hover:scale-110"
                    >
                      <AiFillInstagram
                        size={38}
                        className="fill-current hover:text-black hover:bg-white rounded-full p-1 transition duration-300"
                      />
                    </a>
                    <a
                      href={director.Linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black transition-transform duration-300 transform hover:scale-110"
                    >
                      <AiFillLinkedin
                        size={38}
                        className="fill-current hover:text-black hover:bg-white rounded-full p-1 transition duration-300"
                      />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </LayoutCommon>
    </div>
  );
}

export default Directors;
