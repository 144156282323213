import React, { useEffect, useRef } from "react";
import { LayoutServices } from "../../../components/layout/Layout";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const WhyChoose = ({ data }) => {
  const headingRefs = useRef([]);
  const cardRefs = useRef([]);

  useEffect(() => {
    // Animate headings with GSAP
    headingRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: index * 0.2,
          ease: "power3.out",
        }
      );
    });

    // Animate cards with GSAP and ScrollTrigger
    cardRefs.current.forEach((card, index) => {
      gsap.fromTo(
        card,
        { opacity: 0, scale: 0.9, y: 50 }, // Start off invisible and slightly scaled down
        {
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 1,
          delay: index * 0.2, // Stagger the animations
          ease: "power3.out",
          scrollTrigger: {
            trigger: card,
            start: "top 80%",
            end: "top 30%",
            scrub: true,
            markers: false, // For debugging, set it to true
          },
        }
      );
    });
  }, []);
  const swiperRefs = useRef([]); // Reference to all swiper slides

  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Detect mobile view

    if (isMobile) {
      // Animate the cards with fade-in effect when they come into view
      swiperRefs.current.forEach((swiperSlide, index) => {
        gsap.fromTo(
          swiperSlide,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1,
            delay: index * 0.2, // Delay for each card
            ease: "power3.out",
          }
        );
      });
    }
  }, []);
  return (
    <div className="bg-bgWhite font-poppins">
      <LayoutServices>
        {data.map((item, index) => (
          <div key={index}>
            <h3
              className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold sm:w-[70vw] lg:w-[70vw] leading-relaxed sm:leading-snug md:leading-normal lg:leading-snug xl:leading-[3rem]"
              ref={(el) => (headingRefs.current[index] = el)} // Attach ref to each heading
            >
              {item.head}
            </h3>

            <div className="pt-5 hidden md:block">
              {item.cards.map((card, cardIndex) => (
                <div
                  className="grid grid-cols-1 md:grid-cols-3 my-5"
                  key={cardIndex}
                  ref={(el) => (cardRefs.current[cardIndex] = el)} // Attach ref to each card
                >
                  {/* First Column - Image */}
                  <div className="bg-graymedium md:rounded-l-[32px] md:col-span-1 flex">
                    <img
                      src={card.img}
                      alt={card.alt}
                      className="md:rounded-l-[32px] w-full h-full object-cover"
                    />
                  </div>

                  {/* Second Column - Content */}
                  <div className="bg-skyblue rounded-r-[32px] md:col-span-2 flex flex-col justify-center md:py-8 lg:py-0">
                    <h4 className="mb-2 md:pl-6 lg:pl-0 lg:ml-10 xl:ml-20 text-base sm:text-lg md:text-xl lg:text-2xl 2xl:text-2xl font-semibold">
                      {card.title}
                    </h4>
                    <p className="md:pl-6 lg:pl-0 xl:ml-20 xl:w-[35vw] 2xl:w-[30vw] md:w-[42vw] lg:w-[40vw] lg:ml-10">
                      {card.desc}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* Mobile view with Swiper */}
        <div className="py-5 md:hidden block">
          <Swiper
            spaceBetween={20}
            modules={[Pagination]}
            className="mySwiper"
            breakpoints={{
              320: { slidesPerView: 1.2 },
              480: { slidesPerView: 1.7 },
              640: { slidesPerView: 2 },
            }}
          >
            {data.map((item, index) => (
              <React.Fragment key={index}>
                {item.cards.map((card, cardIndex) => (
                  <SwiperSlide
                    className="rounded-b-[32px] rounded-t-[32px] flex flex-col"
                    key={cardIndex}
                    ref={(el) => (swiperRefs.current[cardIndex] = el)} // Assign ref to each slide
                  >
                    <div className="flex-1 flex items-start flex-col bg-skyblue pb-3 min-h-31 rounded-t-[32px] rounded-b-[32px] md:rounded-none">
                      {/* First Column - Image */}
                      <div className="flex-1">
                        <img
                          src={card.img}
                          alt={card.alt}
                          className="h-full w-full object-cover rounded-t-[32px] md:rounded-none"
                        />
                      </div>
                      {/* Second Column - Content */}
                      <div className="flex flex-col items-start justify-between p-4 rounded-b-[32px] md:rounded-none">
                        <h4 className="mb-2 text-base sm:text-lg md:text-xl lg:text-2xl 2xl:text-2xl text-left font-semibold">
                          {card.title}
                        </h4>
                        <p className="font-regular text-sm md:text-base lg:text-lg text-left flex-grow">
                          {card.desc}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </React.Fragment>
            ))}
          </Swiper>
        </div>
      </LayoutServices>
    </div>
  );
};

export default WhyChoose;
