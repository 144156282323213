import React from "react";
import { LayoutCommon } from "../../components/layout/Layout";
import { MovingText, MovingText2 } from "./MovingText";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { awards } from "../../data/awards";
import Animation from "../../Animation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
function Awards() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/awards-and-recognitions");
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  // Select only the first three awards
  const displayAwards = awards.slice(0, 3);

  // Total slides to display based on screen width
  const slidesPerView =
    window.innerWidth < 640 ? 1 : window.innerWidth < 768 ? 1.3 : 2.5;

  // Handle touch start
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  // Handle touch end
  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  // Handle touch end and determine swipe direction
  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      // Swipe left
      setCurrentSlide((prevSlide) => (prevSlide + 1) % displayAwards.length);
    }

    if (touchEnd - touchStart > 50) {
      // Swipe right
      setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? displayAwards.length - 1 : prevSlide - 1
      );
    }
  };

  return (
    <div className="bg-graydark py-5 md:py-12 xl:py-16">
      <div className="container pt-5 sm:pt-8 md:pt-4 lg:pt-12 xl:pt-12 mx-auto px-3 md:px-0">
        <div>
          <span
            className="typingtext justify-start flex text-start  ju md:text-start text-4xl sm:text-7xl xl:text-9xl font-bold text-transparent"
            style={{
              WebkitTextStroke: "1px #939393",
            }}
          >
            AWARDS & <br /> RECOGNITIONS
          </span>
        </div>

        {/* Custom Slider */}
        <div
          className="relative overflow-hidden mt-2"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${
                currentSlide * (100 / slidesPerView)
              }%)`,
            }}
          >
            {displayAwards.map((award, index) => (
              <div
                key={index}
                onClick={handleClick}
                className="cursor-pointer flex-shrink-0 w-full md:w-1/2 lg:w-1/3 p-2 transform transition-transform duration-200 hover:-translate-y-2"
                data-aos="fade-up-left"
              >
                <div className="relative w-full h-full overflow-hidden rounded-[32px] transform transition-transform duration-200 hover:-translate-y-2  shadow-black">
                  {award.images && award.images[0] && (
                    <>
                      <img
                        src={award.images[0].img}
                        alt={award.images[0].alt}
                        className="w-full h-full object-cover rounded-[32px]"
                      />
                      <div className=" absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-black to-transparent opacity-90 transition-opacity duration-300 flex items-end p-3 lg:p-4 rounded-[32px]">
                        <div className="flex flex-col hidded hover:block">
                          <h3 className="text-md font-semibold  text-peach">
                            {award.year}
                          </h3>
                          <h3 className="text-lg font-normal  text-gray-300">
                            {award.title}
                          </h3>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* View All button */}
        <div className="flex justify-end mt-5 lg:mt-8">
          <button
            onClick={handleClick}
            className="hover:bg-primaryBlue hover:text-white border-2 font-medium
              border-primaryBlue bg-skyblue text-primaryBlue p-1 px-3 md:p-2 md:px-5 cursor-pointer transform transition-transform duration-200 hover:scale-105 rounded-full text-base md:text-lg lg:text-xl"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            View All
          </button>
        </div>
      </div>
    </div>
  );
}

export default Awards;
