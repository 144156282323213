export const about = [
  {
    head: "About Us",
    desc: "At ELF Shipping, we are more than just a logistics company. We are your dedicated partner, committed to simplifying your logistics challenges and ensuring your goods reach their destination seamlessly. With a legacy of excellence and a team of passionate professionals, we stand as Dubai's premier logistics service provider",

    mainImage: "./Images/about/About.webp",
    subtitle: "Our Journey",
    subtitle2: "Our Values",
    team: "./Images/about/team.webp",
    subpara:
      "Embark on a remarkable journey with ELF Shipping, a logistics powerhouse that began its odyssey in 2012. Founded by four visionaries, we set out with a commitment to redefine the standards of logistics and warehousing. Over the years, our unwavering dedication has propelled us to handle diverse projects across the globe. Our journey has been punctuated with milestones, including prestigious awards that underscore our excellence, In 2013, we proudly received the Best Domestic Agent Award, and our commitment to outstanding service was further recognised with the Best Domestic Agent Award in 2016. The accolades continued, with the Second Best Domestic On-site Agent Award in 2018, the Best On-Site Agent Award in 2019, and the Second Best Domestic On-site Agent Award in 2022, solidifying our position as an industry leader. At ELF Shipping, our story extends beyond accolades. Nestled in the National Industrial Park, our expansive warehouse is a testament to our commitment to providing top-notch logistics & warehouse solutions. This vast space, coupled with a team of seasoned experts, ensures that we meet and exceed the expectations of our clients.Join us on this extraordinary journey where passion, expertise, and a relentless pursuit of excellence shape the way we navigate the logistics landscape.",
  },
];
export const values = [
  {
    title: "CUSTOMER SERVICE",
    desc: "Elf Shipping prioritizes high-quality service to clients, ensuring their shipments are delivered on time and in excellent condition, meeting all expectations.",
  },
  {
    title: "INTEGRITY",
    desc: "Elf Shipping conducts business with honesty and transparency, building trust with partners and reinforcing strong, long-term relationships.",
  },
  {
    title: "INNOVATION",
    desc: "Elf Shipping invests in new technologies and processes to enhance operations and maintain a competitive edge in the ever-evolving shipping industry.",
  },
  {
    title: "SUSTAINABILITY",
    desc: "Elf Shipping is committed to minimizing environmental impact by reducing emissions, managing waste, and implementing sustainable, eco-friendly practices.",
  },
  {
    title: "TEAMWORK",
    desc: "Elf Shipping fosters a collaborative work environment, encouraging employees to work together effectively to achieve the company's goals and drive shared success.",
  },
  {
    title: "TRANSPARENCY",
    desc: "Elf Shipping builds trust through open, honest communication with clients and partners, offering clear updates at each stage of the shipping process.",
  },
];
export const directors = [
  {
    name: "Manoj Sharma",
    position: "Managing Director",
    Instagram: "https://www.instagram.com/manojelf/",
    Linkedin: "https://www.linkedin.com/in/manoj-sharma-31b04324/",
    image: "./Images/about/Directors/Manoj-Kumar.png",
    alt: "Elf-Shipping-Managing-Director-Manoj-Kumar",
    desc: "Manoj Kumar is an Indian National, A Commerce Graduate from Delhi University having 30 years of experience in the Exhibition Freight Forwarding Industry. Founder member of ELF Shipping since 2012.",
  },
  {
    name: "Irshad Khan",
    position: "Director",
    Instagram: "https://www.instagram.com/irshad552khan/",
    Linkedin: "https://www.linkedin.com/in/irshad-khan-09a92016/",
    image: "./Images/about/Directors/Irshad-Khan.png",
    alt: "Elf-Shipping-Director-Irshad-Khan",
    desc: "Irshad Khan an Indian National & Science Graduate from Mumbai, India has been in the Freight forwarding Industry for the last 30+ years in UAE. His past Experience with Freight work a joint venture of DNATA (Emirates Group) and Kanoo Group both household names in the UAE. ",
  },
  {
    name: "Mubashir Ahmed",
    position: "Director",
    Instagram: "https://www.instagram.com/b_mubashir2021/",
    Linkedin: "https://www.linkedin.com/in/mubashir-ahmed-97521296/",
    image: "./Images/about/Directors/Mubashir-Ahmed.png",
    alt: "Elf-Shipping-Director-Mubashir-Ahmed",
    desc: "Mubashir Ahmed is an Indian National, a Science Graduate from Calicut University having 30 years of experience in UAE Market.  He is also a founder member of ELF Shipping since 2012.",
  },
  {
    name: "Binu Thomas",
    position: "Director",
    Instagram: "https://www.instagram.com/binukanichai/",
    Linkedin: "https://www.linkedin.com/in/binu-thomas-kanichai-329a0350/",
    image: "./Images/about/Directors/Binu-Thomas.png",
    alt: "Elf-Shipping-Director-Binu-Thomas",
    desc: "Binu Thomas is an Indian National, Holding Master Degree in International Business (MBA-IB) from Annamalai University. Having 25+ years of experience in handling Customs clearance, documentation and operations in Shipping Lines and Freight Forwarding companies in UAE and Gulf Region. He is working with ELF Shipping since 2012.",
  },
];
export const Client = [
  {
    img: "./Images/clients/logos/promise.svg",
    alt: "Promise Logistics",
  },
  {
    img: "./Images/clients/logos/BTG.svg",
    alt: "BTG Logistics",
  },

  {
    img: "./Images/clients/logos/Rogers.svg",
    alt: "Rogers Shipping",
  },
  {
    img: "./Images/clients/logos/RoutersGlobal.svg",
    alt: "Routers Global",
  },

  {
    img: "./Images/clients/logos/UniversalExpress.svg",
    alt: "Universal Express",
  },
  {
    img: "./Images/clients/logos/ESI.svg",
    alt: "ESI Logistics",
  },
  {
    img: "./Images/clients/logos/Harlas.svg",
    alt: "Harlas Shipping",
  },

  {
    img: "./Images/clients/logos/Insignia.svg",
    alt: "Insignia Shipping Services",
  },
  {
    img: "./Images/clients/logos/Koreagls.png",
    alt: "Korea GLS",
  },
  {
    img: "./Images/clients/logos/Bluewater.svg",
    alt: "Bluewater Logistics",
  },
  {
    img: "./Images/clients/logos/Masters.svg",
    alt: "Masters Shipping",
  },
  {
    img: "./Images/clients/logos/Onsite.svg",
    alt: "Onsite Logistics",
  },
  {
    img: "./Images/clients/logos/expocargo.svg",
    alt: "Expocargo",
  },
  {
    img: "./Images/clients/logos/expotrans.svg",
    alt: "Expotrans",
  },
  {
    img: "./Images/clients/logos/Impeco.svg",
    alt: "Impeco Logistics",
  },
  {
    img: "./Images/clients/logos/PLS.svg",
    alt: "PLS Logistics",
  },
  {
    img: "./Images/clients/logos/GBH.svg",
    alt: "GBH Logistics",
  },
  {
    img: "./Images/clients/logos/Ishikawa.svg",
    alt: "Ishikawa Shipping",
  },
  {
    img: "./Images/clients/logos/PS.svg",
    alt: "PS Logistics",
  },
  {
    img: "./Images/clients/logos/S&S.svg",
    alt: "S&S Shipping",
  },
  {
    img: "./Images/clients/logos/Schafer.svg",
    alt: "Schafer Logistics",
  },
  {
    img: "./Images/clients/logos/TDS.png",
    alt: "TDS Logistics",
  },
  {
    img: "./Images/clients/logos/GKL.svg",
    alt: "GKL Logistics",
  },
  {
    img: "./Images/clients/logos/ESM.png",
    alt: "ESM Logistics",
  },
  {
    img: "./Images/clients/logos/8Event.svg",
    alt: "8 Event Shipping",
  },
  {
    img: "./Images/clients/logos/MiddleBy.svg",
    alt: "MiddleBy Shipping",
  },
  {
    img: "./Images/clients/logos/PSP.svg",
    alt: "PSP World Wide Logistics",
  },
  {
    img: "./Images/clients/logos/Buus.svg",
    alt: "Buus Shipping",
  },
];

export const whychoose = [
  {
    head: "Industry Excellence",
    desc: "With over 30 years of expertise, we stand as a beacon of reliability in the logistics landscape.",
  },

  {
    head: "Tailored Solutions",
    desc: "Our commitment is to tailor logistics solutions to meet your unique cargo needs.",
  },
  {
    head: "Customer-Centric Approach",
    desc: "ELF Shipping prioritizes your satisfaction, ensuring seamless communication and personalized support.",
  },
  {
    head: "Award-Winning Service",
    desc: "With over 30 years of expertise, we stand as a beacon of reliability in the logistics landscape.",
  },
];
