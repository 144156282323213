import React, { useState } from "react";
import {
  AiOutlineLinkedin,
  AiFillLinkedin,
  AiFillInstagram,
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiFillFacebook,
} from "react-icons/ai";
export const Social = () => {
  <div className="flex ">
    <Linkedin />
    <Instagram />
    <Facebook />
  </div>;
};

export const Instagram = () => {
  const [isHovered, setHovered] = useState(false);
  const handleClick = () => {
    const instagramUrl = "https://www.instagram.com/elfshipping/";
    window.open(instagramUrl, "_blank");
  };

  return (
    <div
      className="flex "
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
      {isHovered ? (
        <i>
          <AiFillInstagram />
        </i>
      ) : (
        <i>
          <AiOutlineInstagram />
        </i>
      )}
    </div>
  );
};
export const Linkedin = () => {
  const [isHovered, setHovered] = useState(false);
  const handleClick = () => {
    const linkedinUrl = "https://www.linkedin.com/company/elf-shipping-llc/";
    window.open(linkedinUrl, "_blank");
  };

  return (
    <div
      className="flex "
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
      {isHovered ? (
        <i>
          <AiFillLinkedin />
        </i>
      ) : (
        <i>
          <AiOutlineLinkedin />
        </i>
      )}
    </div>
  );
};
export const Facebook = () => {
  const [isHovered, setHovered] = useState(false);
  const handleClick = () => {
    const facebookurl = "https://www.facebook.com/ELFShipping";
    window.open(facebookurl, "_blank");
  };

  return (
    <div
      className="flex "
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
      {isHovered ? (
        <i>
          <AiFillFacebook />
        </i>
      ) : (
        <i>
          <AiOutlineFacebook />
        </i>
      )}
    </div>
  );
};
