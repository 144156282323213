import React from "react";
import { navButton } from "../../data/Button";
import { useNavigate } from "react-router-dom";

export const ServiceButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {navButton.map((item) => (
        <button
          className="bg-primaryBlue text-white border-2 font-medium
 border-primaryBlue hover:bg-skyblue hover:text-primaryBlue p-1 px-3 md:p-2 md:px-5   rounded-full text-base md:text-lg lg:text-xl"
          onClick={handleClick}
        >
          Learn More
        </button>
      ))}
    </div>
  );
};
