import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Layout } from "../../components/layout/Layout";
import Navbar from "../../components/navbar/Navbar";
import { awards } from "../../data/awards";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import Footer from "../../components/footer/Footer";
import CookieBanner from "../../components/cookie/CookieBanner";
import { AwardsPage } from "../../components/helmet/ReactHelmet";

function Awards() {
  const headingRef = useRef(null);
  const descRef = useRef(null);
  const titleRefs = useRef([]); // Use a ref array to target each title
  const yearRefs = useRef([]); // Use a ref array for the years

  // Initialize refs arrays
  titleRefs.current = [];
  yearRefs.current = [];

  const addToRefs = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Animate heading and description
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: headingRef.current,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      },
    });

    tl.fromTo(
      headingRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: "power2.out" }
    );
    tl.fromTo(
      descRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: "power2.out" },
      "+=0.2"
    );
    yearRefs.current.forEach((yearRef) => {
      gsap.fromTo(
        yearRef,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          delay: 0.5,
          scrollTrigger: {
            trigger: yearRef,
            start: "top 80%",
            end: "bottom 20%",
            scrub: false,
          },
        }
      );
    });
    // Animate titles
    titleRefs.current.forEach((titleRef) => {
      gsap.fromTo(
        titleRef,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1, ease: "power2.out", delay: 0.9 }
      );
    });

    // Animate years

    // Animate Swiper images
    document.querySelectorAll(".swiper-slide img").forEach((img) => {
      gsap.fromTo(
        img,
        { scale: 0.8, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: img,
            start: "top 80%",
            end: "bottom 20%",
            scrub: false,
          },
        }
      );
    });

    // Cleanup on unmount
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className="bg-bgWhite">
      <AwardsPage />
      <Navbar />
      <CookieBanner />
      <div className="pt-4 sm:pt-6 md:pt-16 lg:pt-12 xl:pt-16 bg-bgWhite">
        <Layout>
          <h1
            ref={headingRef}
            className="font-arimo text-3xl sm:text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl font-semibold"
          >
            Awards & Recognitions
          </h1>
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-3/6"></div>
            <p
              ref={descRef}
              className="mt-3 md:mt-5 xl:mt-12 lg:w-2/6 self-end"
            >
              Step into the world of ELF Shipping's Awards and Recognition.
              Here, we take great pride in highlighting our exceptional journey
              in logistics and exhibition logistics. Join us in celebrating the
              milestones, innovations, and the trust our valued partners have
              bestowed upon us.
            </p>
          </div>

          {awards.map((item, index) => (
            <div className="mt-6 sm:mt-8 md:mt-12 lg:mt-16" key={index}>
              <span
                ref={(el) => addToRefs(el, yearRefs)}
                className="text-5xl md:text-6xl lg:text-7xl text-graydark font-semibold"
              >
                {item.year}
              </span>
              <h3
                ref={(el) => addToRefs(el, titleRefs)}
                className="top-[-40px] text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mt-1 sm:mt-2 md:mt-2 lg:mt-3"
              >
                {item.title}
              </h3>

              {item.images && (
                <div className="relative">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    breakpoints={{
                      0: {
                        slidesPerView: 1.4,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 25,
                      },
                      1260: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                    }}
                    pagination={{ clickable: true }}
                    navigation={{
                      nextEl: ".custom-swiper-button-next",
                      prevEl: ".custom-swiper-button-prev",
                    }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    onSwiper={(swiper) => {
                      swiper.navigation.init();
                      swiper.navigation.update();
                    }}
                  >
                    {item.images.map((image, idx) => (
                      <SwiperSlide
                        key={idx}
                        className="rounded-xl lg:rounded-[32px] my-4 md:my-6 lg:my-8 bg-transparent"
                      >
                        <img
                          src={image.img}
                          alt={image.alt}
                          className="bg-transparent rounded-xl lg:rounded-[32px] lg:max-h-[70vh] drop-shadow-xl w-full"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="absolute top-[-50px] right-0 z-10 flex gap-2 mr-4 md:top-[-50px]">
                    <div className="custom-swiper-button-prev hidden md:block text-lineblue cursor-pointer">
                      <BsArrowLeftCircle size={30} />
                    </div>
                    <div className="custom-swiper-button-next hidden md:block text-lineblue cursor-pointer">
                      <BsArrowRightCircle size={30} />
                    </div>
                  </div>
                </div>
              )}

              <p className="lg:w-[80vw] xl:w-[75vw] 2xl:w-[70vw]">
                {item.desc}
              </p>
            </div>
          ))}
        </Layout>
        <style>{`
          .swiper-pagination {
            display: none;
          }
        `}</style>
      </div>
      <div className="mt-12 md:mt-16 lg:mt-24"></div>
      <Footer />
    </div>
  );
}

export default Awards;
