export const Banner = [
  {
    head: "Exhibition & Event Logistics ",
    img: "./Images/services/exhibition/mainbanner.webp",
    alt: "Expert exhibition and event logistics services",
    decsription:
      "We redefine exhibition and event  logistics with meticulous planning, seamless coordination, and a commitment to exceed industry standards.",
  },
];
export const Introduction = [
  {
    img: "./Images/services/exhibition/intromain.webp",
    alt: "Logistics support for global exhibitions and event",
    headline: "Your Partner in Precision Logistics",
    desc: " At ELF Shipping, we specialize in cutting-edge exhibition logistics services in Dubai, UAE, delivering seamless end-to-end solutions. From transportation to setup, our expert team ensures precision, reliability, and excellence for your events. Trust us to handle every detail, guaranteeing a hassle-free experience and outstanding results in the heart of the UAE logistics industry.",
  },
];
export const Choose = [
  {
    head: "Why Choose ELF Shipping for Your Exhibition and Event Logistics Needs?",
    cards: [
      {
        img: "./Images/services/exhibition/seamlesscoordination.webp",
        alt: "seamless coordination- Seamless logistics solutions for events and exhibitions",
        title: "Seamless Coordination",
        desc: "From the initial planning stages to on-site execution, our team seamlessly coordinates every aspect of your exhibition or event logistics. We work tirelessly to ensure that every detail is meticulously managed for a smooth and successful experience.",
      },
      {
        img: "./Images/services/exhibition/Global-reach.webp",

        alt: "Professional event logistics management",
        title: "Global Reach",
        desc: "With a global network and strategic alliances, ELF Shipping provides exhibition and event logistics services that transcend borders. No matter where your event takes place, we have the expertise and resources to handle logistics on a global scale.",
      },
      {
        img: "./Images/services/exhibition/Tailored-solutions.webp",

        alt: "Tailored logistics services for trade shows and events",
        title: "Tailored Solutions",
        desc: " Experience logistics tailored to the unique requirements of exhibitions and events. We offer end-to-end solutions, encompassing transportation, setup, and coordination, allowing you to focus on the success of your showcase.",
      },
    ],
  },
];
export const Subsection = [
  {
    img: "./Images/services/exhibition/subsection.webp",
    alt: "Logistics support for global exhibitions and events",
    headline:
      "Uplift Your ExhibitionsWith ELF Shipping's Unmatched Excellence, Experience a New Standard in Logistics",
    desc: "Embark on a journey of unparalleled exhibition excellence with ELF Shipping. Our commitment to precision and seamless logistics ensures that your exhibits take center stage with an impactful presence. From meticulous transportation to on-site coordination, our dedicated team handles every detail to showcase your offerings in the best light possible. With ELF Shipping, your exhibits not only reach their destination safely but also create lasting impressions. Trust us to elevate your showcase with a touch of excellence that goes beyond logistics – it's an experience crafted for success.",
  },
];
export const Client = [
  {
    name: "John",
    desc: "“Elf Shipping exceeds expectations! Their professionalism shines through every project without fail.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Simon Markose",
    desc: "“Elf Shipping's service is exceptional! They consistently provide reliable logistics solutions daily.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Aimy Aman",
    desc: "“Their leadership sets a high standard, using technology to ensure operations run very smoothly here.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Musthafa",
    desc: "“I was impressed with how Elf Shipping handled our urgent request with quick responses and great care.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Veena",
    desc: "“Fantastic experience with Elf Shipping! They made the entire process easy and stress-free for all.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Sophia",
    desc: "“The team at Elf Shipping is always ready to help! Their customer service is top-notch and responsive!”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
];
