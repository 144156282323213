import React from "react";
import { LayoutServices } from "../../../components/layout/Layout";
import { useState, useEffect } from "react";
import {
  Icons,
  NumberBannerData,
  NumberMobile,
  NumberTablet,
} from "../../../data/services/banner";
export const ResponsiveImage = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let imageComponent;

  if (screenWidth >= 1024) {
    // Render desktop image
    imageComponent = <DesktopImage />;
  } else if (screenWidth >= 640) {
    // Render tablet image
    imageComponent = <TabletImage />;
  } else {
    // Render mobile image
    imageComponent = <MobileImage />;
  }

  return <div>{imageComponent}</div>;
};
const DesktopImage = () => {
  return (
    <>
      {NumberBannerData.map((item) => (
        <img
          src={item.img}
          alt={item.alt}
          className="object-cover w-full h-full block p-0 m-0"
        />
      ))}
    </>
  );
};

const TabletImage = () => {
  return (
    <>
      {NumberTablet.map((item) => (
        <img
          src={item.img}
          alt={item.alt}
          className="object-cover w-full h-full block p-0 m-0"
        />
      ))}
    </>
  );
};

const MobileImage = () => {
  return (
    <>
      {NumberMobile.map((item) => (
        <img
          src={item.img}
          alt={item.alt}
          className="object-cover w-full h-full block p-0 m-0"
        />
      ))}
    </>
  );
};
const Counter = ({ endNumber }) => {
  const [count, setCount] = useState(0);
  const duration = 20000; // Total duration in milliseconds
  const increment = Math.ceil(endNumber / (duration / 100)); // Calculate the increment step

  useEffect(() => {
    let currentCount = 0;

    const animateCount = () => {
      if (currentCount < endNumber) {
        currentCount += increment; // Increment the count
        if (currentCount > endNumber) currentCount = endNumber; // Ensure we don't exceed the target
        setCount(currentCount);
        requestAnimationFrame(animateCount); // Continue the animation
      }
    };

    // Start the animation
    requestAnimationFrame(animateCount);

    return () => setCount(0); // Cleanup on unmount
  }, [endNumber, increment]);

  return (
    <span className="text-white pt-3 lg:pt-5 sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-medium">
      {count}+
    </span>
  );
};

export const NumBanner = () => {
  return (
    <div>
      <div className="bg-bgWhite">
        <div className="pt-6 sm:pt-8 md:pt-12 lg:pt-16 xl:pt-20 mx-auto relative">
          {/* Background image */}
          <ResponsiveImage />

          {/* Icons and text over the background image */}
          <div className="absolute inset-0 flex items-center justify-center h-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 sm:gap-8 md:gap-12 px-6 md:px-12 lg:px-24 w-full">
              {Icons.map((icon, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center h-48 justify-center"
                >
                  {/* Increase icon size for small devices */}
                  <img
                    src={icon.img}
                    alt={icon.alt}
                    className="w-16 h-16 sm:w-16 sm:h-16"
                  />
                  <h4 className="text-white mt-1 lg:mt-5 text-lg sm:text-2xl md:text-2xl lg:text-3xl 2xl:text-4xl font-medium">
                    {icon.text}
                  </h4>
                  <Counter endNumber={icon.number} /> {/* Number Counter */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
