import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cookie } from "../../data/component";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");

    if (!cookiesAccepted) {
      // Show the banner after 2000ms (2 seconds)
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 2000);

      return () => clearTimeout(timer); // Clean up the timer
    }
  }, []);

  const handleAccept = () => {
    setIsVisible(false);
    localStorage.setItem("cookiesAccepted", "true");
  };

  const handleCookiePolicy = () => {
    window.scrollTo(0, 0);
    navigate("/cookie-policy");
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 left-4 w-11/12 sm:w-5/12 md:w-4/12 xl:w-3/12 bg-gray-900 text-white py-2 px-3 flex items-center shadow-lg rounded-lg z-20 text-xs">
      {/* Lottie or Image Section */}
      <div className="flex-shrink-0 w-12 h-12 mr-2">
        {cookie.map((val) => (
          <img
            src={val.img} // Replace with your image or Lottie animation
            alt={val.alt}
            className="w-full h-full object-cover rounded-md"
          />
        ))}
      </div>
      {/* Content Section */}
      <div className="flex-grow">
        <p>
          We use cookies. See our{" "}
          <button
            onClick={handleCookiePolicy}
            className="text-primaryBlue underline font-semibold hover:text-blue-500"
          >
            Cookie Policy
          </button>
          .
        </p>
      </div>
      {/* Buttons Section */}
      <button
        onClick={handleAccept}
        className="ml-2 py-1 px-3 bg-primaryBlue text-white rounded-md hover:bg-blue-700 focus:outline-none"
      >
        Accept
      </button>
    </div>
  );
};

export default CookieBanner;
