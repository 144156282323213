import React, { useEffect } from "react";
import { ServiceButton } from "../../../components/buttons/ServiceButton";
import Animation from "../../../Animation";
import AOS from "aos";
import "aos/dist/aos.css";
const ServiceMenu = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <>
      {/**Desktop */}
      <div className="bg-bgWhite relative hidden lg:block">
        {/* First section */}
        <div className="border-b-2 border-lineblue w-full" />
        <div className="flex flex-row justify-between w-full py-5">
          <div className="text-left flex items-center ms-[10vw]">
            <span
              data-aos="fade-right"
              data-aos-delay="500"
              className="text-lineblue text-6xl sm:text-5xl md:text-7xl font-bold"
            >
              ELF
            </span>
          </div>
          <div
            data-aos="zoom-in"
            data-aos-delay="400"
            className="flex flex-row justify-between gap-5 h-full"
          >
            <a href="/exhibition-and-event-logistics" className="block h-full">
              <p className="hover-effect text-center p-8 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg text-lg h-full min-h-500px  ">
                Exhibition &
                <br />
                Event Logistics
              </p>
            </a>
            <a
              href="/project-and-heavy-cargo-handling"
              className="block h-full"
            >
              <p className="hover-effect text-center p-8 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg h-full">
                Project &
                <br />
                Heavy Cargo Handling
              </p>
            </a>
            <a href="/ocean-freight-forwarding" className="block h-full">
              <p className="hover-effect text-center p-8 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg me-[10vw] h-full">
                Ocean Freight
                <br />
                Forwarding
              </p>
            </a>
          </div>
        </div>

        {/* Second section */}
        <div className="border-b-2 border-lineblue w-full" />
        <div className="flex flex-row justify-between w-full py-5">
          <div
            data-aos="zoom-in"
            data-aos-delay="800"
            className="flex flex-row justify-between gap-5 ms-[10vw] h-full"
          >
            <a href="/gcc-trucking" className="block h-full">
              <p className="hover-effect text-center p-8 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg text-lg h-full">
                GCC
                <br />
                Trucking
              </p>
            </a>
            <a href="/storage-and-warehousing" className="block h-full">
              <p className="hover-effect text-center p-8 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg h-full">
                Storage &
                <br />
                Warehousing
              </p>
            </a>
            <a href="/air-freight-forwarding" className="block h-full">
              <p className="hover-effect text-center p-8 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg h-full">
                Air Freight
                <br />
                Forwarding
              </p>
            </a>
          </div>
          <div className="text-left flex items-center">
            <span
              data-aos="fade-left"
              data-aos-delay="1200"
              className="text-lineblue text-6xl sm:text-5xl md:text-7xl font-bold me-[10vw] xl:me-[10vw]"
            >
              SHIPPING
            </span>
          </div>
        </div>

        {/* Third section */}
        <div className="border-b-2 border-lineblue w-full" />
        <div className="flex flex-row justify-between w-full py-12">
          <div className="w-1/2 text-left flex items-center ms-[10vw]">
            <span
              data-aos="fade-right"
              data-aos-delay="1600"
              className="text-lineblue text-6xl sm:text-5xl md:text-7xl font-bold"
            >
              LOGISTICS
            </span>
          </div>
          <div
            className="flex flex-row w-1/2 lg:gap-8"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="2000"
            data-aos-offset="0"
          >
            <h3 className="w-3/6 text-left justify-center flex items-center text-lineblue font-medium rounded-lg text-lg">
              We’re leading logistics solution provider, recognised for
              excellence in service
            </h3>
            <div className="flex items-center">
              <ServiceButton />
            </div>
          </div>
        </div>
      </div>

      <div className="block lg:hidden">
        {/**Mobile */}
        <Animation />
        <div className="flex flex-row justify-center gap-5 py-5 bg-bgWhite">
          <a href="/exhibition-and-event-logistics" className="block">
            <p className="text-center p-5 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg text-lg min-w-[150px] min-h-[120px] sm:min-w-[200px] sm:min-h-[150px]">
              Exhibition &
              <br />
              Event Logistics
            </p>
          </a>
          <a href="/gcc-trucking" className="block">
            <p className="text-center p-5 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg text-lg min-w-[150px] min-h-[120px] sm:min-w-[200px] sm:min-h-[150px]">
              GCC
              <br />
              Trucking
            </p>
          </a>
        </div>

        <div className="flex flex-row justify-center gap-5 pb-5 bg-bgWhite">
          <a href="/ocean-freight-forwarding" className="block">
            <p className="text-center p-5 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg min-w-[150px] min-h-[120px] sm:min-w-[200px] sm:min-h-[150px]">
              Ocean Freight
              <br />
              Forwarding
            </p>
          </a>
          <a href="/air-freight-forwarding" className="block">
            <p className="text-center p-5 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg min-w-[150px] min-h-[120px] sm:min-w-[200px] sm:min-h-[150px]">
              Air Freight
              <br />
              Forwarding
            </p>
          </a>
        </div>

        <div className="flex flex-row justify-center gap-5 pb-5 bg-bgWhite">
          <a href="/project-and-heavy-cargo-handling" className="block">
            <p className="text-center p-5 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg min-w-[150px] min-h-[120px] sm:min-w-[200px] sm:min-h-[150px]">
              Project &
              <br />
              Heavy Cargo Handling
            </p>
          </a>
          <a href="/storage-and-warehousing" className="block">
            <p className="text-center p-5 bg-graymedium flex items-center justify-center text-lineblue font-semibold rounded-lg min-w-[150px] min-h-[120px] sm:min-w-[200px] sm:min-h-[150px]">
              Storage &
              <br />
              Warehousing
            </p>
          </a>
        </div>
      </div>
    </>
  );
};

export default ServiceMenu;
