import React, { useEffect } from "react";
import { footer, menu, services } from "../../data/footer";
import { Address, Contact, Fax, Mail } from "../buttons/ReachUs";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin, Social } from "../social-icons/Social";
import { useNavigate } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";

const Footer = () => {
  const handleHomeClick = () => {
    window.location.href = "/";
  };
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path); // Navigate to the specified path
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  const handleClickPrivacy = () => {
    navigate("/privacy-policy");
    window.scrollTo(0, 0);
  };
  const handleClickCookie = () => {
    navigate("/cookie-policy");
    window.scrollTo(0, 0);
  };
  const handleClickSitemap = () => {
    navigate("/sitemap");
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    AOS.init({
      duration: 1500, // Animation duration
      once: true, // Animate only once
    });
  }, []);
  return (
    <footer className=" bg-skyblue  z-10   bottom-0 w-full">
      <div
        data-aos="fade-in"
        data-aos-delay="200"
        className="container mx-auto px-3 md-px-0 py-3 md:py-4 lg:py-4"
      >
        <div className="grid">
          <div className="justify-self-end">
            <div className="flex cursor-pointer gap-1">
              <h4 className="text-black hover:text-primaryBlue  text-3xl">
                <Linkedin />
              </h4>
              <h4 className="text-black hover:text-primaryBlue text-3xl">
                <Instagram />
              </h4>
              <h4 className="text-black hover:text-primaryBlue  text-3xl">
                <Facebook />
              </h4>
            </div>
          </div>
        </div>

        <div className="grid sm:grid-cols-1 lg:grid-cols-2 py-5 ">
          <div className="flex justify-between  ">
            {footer.map((item) => (
              <div className="">
                <img
                  src={item.logo}
                  alt={item.alt}
                  className="mb-5 mt-2 h-20 w-20"
                  onclick={handleHomeClick}
                />
                <div className="my-2">
                  <Address />
                </div>
                <div className="my-2">
                  <Mail />
                </div>
                <div className="my-2">
                  <Contact />
                </div>
                <div className="my-2">
                  <Fax />
                </div>
              </div>
            ))}
          </div>
          <div className="flex sm:gap-16 lg:gap-0 lg:justify-around  mt-5 lg:mt-0 ">
            <div>
              <ul className="list-none text-left">
                <h5 className="text-black text-lg md:text-xl lg:text-2xl">
                  Overview
                </h5>
                {menu.map((item, index) => (
                  <li
                    key={index}
                    className="my-2 text-black hover:text-primaryBlue font-medium cursor-pointer"
                    onClick={() => handleNavigation(item.onclick)} // Navigate on click
                  >
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <ul className="list-none text-left">
                <h5 className="text-black text-lg md:text-xl lg:text-2xl">
                  Services
                </h5>

                {services.map((item, index) => (
                  <li
                    key={index}
                    className="my-2 text-black hover:text-primaryBlue font-medium cursor-pointer"
                    onClick={() => handleNavigation(item.onclick)} // Navigate on click
                  >
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div class="border-t border-blacklight w-full   mx-auto"></div>
        <div className="flex lg:flex-row flex-col justify-between">
          <div className="flex md:flex-row flex-col  text-blacklight font-light md:gap-6 gap-2 mt-4 sm:mt-4 lg:my-5">
            <p>© 2023 ELF Shipping LLC. All rights reserved.</p>
            <div onClick={handleClickPrivacy}>
              <p className="cursor-pointer hover:text-black">Privacy Policy</p>
            </div>
            <div onClick={handleClickCookie}>
              <p className="cursor-pointer hover:text-black">Cookie Policy</p>
            </div>
            <div onClick={handleClickSitemap}>
              <p className="cursor-pointer hover:text-black">Sitemap</p>
            </div>
          </div>
          <div>
            <p className=" text-blacklight mt-2   md:mt-2 lg:my-5">
              Powered by
              <Link
                to="https://www.linkedin.com/in/asldigital/"
                className="cursor-pointer hover:text-black ms-2"
              >
                ASL Digital
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
