export const Banner = [
  {
    head: "GCC Trucking",
    img: "./Images/services/gcc-trucking/mainbanner.webp",
    alt: "GCC Trucking main banner featuring a fleet of trucks on the road",

    decsription:
      "Efficient and timely transport across the  GCC region. Experience seamless logistics with our GCC trucking services. Your cargo's journey, simplified.",
  },
];
export const Introduction = [
  {
    img: "./Images/services/gcc-trucking/trucking-introduction.webp",
    alt: "Introduction to ELF Shipping's GCC Trucking Services",
    headline: "Connecting Your Cargo Safely and Swiftly",
    desc: "Discover a reliable and efficient way to transport your cargo across the GCC region with ELF Shipping's dedicated trucking services. Our extensive fleet, equipped with cutting-edge tracking systems, guarantees the secure and prompt delivery of your goods. Whether you require a one-time shipment or ongoing logistics support, we offer personalized solutions tailored to meet the specific transportation needs of your business.",
  },
];

export const Choose = [
  {
    head: "Why Choose ELF Shipping for Your Trucking Needs?",
    cards: [
      {
        img: "./Images/services/gcc-trucking/cross-border-transport.webp",
        alt: "Reliable cross-border transport trucks",
        title: "Reliable Cross-Border Transport",
        desc: "Our GCC Trucking Services ensure reliable and efficient cross-border transportation across the Gulf Cooperation Council. Trust us for seamless logistics solutions that transcend geographical boundaries.",
      },
      {
        img: "./Images/services/gcc-trucking/timely-secure-deliveries.webp",
        alt: "Truck delivering cargo securely and on time",
        title: "Timely & Secure Deliveries",
        desc: "Experience the assurance of timely and secure deliveries with our GCC Trucking. Our dedicated fleet and experienced team guarantee that your cargo reaches its destination safely and on schedule.",
      },
      {
        img: "./Images/services/gcc-trucking/supply-chain-connectivity.webp",
        alt: "Supply chain connectivity through GCC trucking services",
        title: "Efficient Supply Chain Connectivity",
        desc: "Choose ELF Shipping for GCC Trucking services that contribute to an efficient supply chain. We prioritize connectivity, ensuring your goods move seamlessly through the Gulf region for a streamlined logistics experience.",
      },
    ],
  },
];

export const Subsection = [
  {
    img: "./Images/services/gcc-trucking/trucking-expertise.webp",
    alt: "ELF Shipping's expertise in navigating GCC routes",
    headline:
      "Efficiently Navigating GCC Routes with ELF Shipping's Proven Trucking Expertise",
    desc: "Choose from our diverse fleet of vehicles to ensure the right fit for your cargo size and specifications, benefit from cutting-edge tracking systems for real-time updates on your cargo's journey, and prioritize safety through stringent security measures. Tailor your trucking services to meet unique cargo requirements, whether for a one-time shipment or an ongoing partnership. Our dedicated team provides proactive support, addressing queries or concerns promptly and efficiently.",
  },
];
export const Client = [
  {
    name: "John",
    desc: "“Elf Shipping exceeds expectations! Their professionalism shines through every project without fail.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Simon Markose",
    desc: "“Elf Shipping's service is exceptional! They consistently provide reliable logistics solutions daily.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Aimy Aman",
    desc: "“Their leadership sets a high standard, using technology to ensure operations run very smoothly here.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Musthafa",
    desc: "“I was impressed with how Elf Shipping handled our urgent request with quick responses and great care.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Veena",
    desc: "“Fantastic experience with Elf Shipping! They made the entire process easy and stress-free for all.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Sophia",
    desc: "“The team at Elf Shipping is always ready to help! Their customer service is top-notch and responsive!”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
];
