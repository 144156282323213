export const BannerData = [
  {
    img: "./Images/backgrounds/BannerBackground.png",
    alt: "Logistics banner showcasing efficient services",
    text: "Ready to elevate your logistics experience? Connect with us now",
  },
];

export const Bannerstorage = [
  {
    img: "./Images/backgrounds/secure-and-efficient-storage-solutions.png",
    alt: "Banner highlighting secure and efficient storage solutions",
    text: "Looking for secure and efficient storage solutions? Connect with us now",
  },
];

export const NumberBannerData = [
  {
    img: "./Images/backgrounds/numberbackgroundsktop.png",
    alt: " logistics banner with statistical highlights",
    text: "Ready to elevate your logistics experience? Connect with us now",
  },
];

export const NumberTablet = [
  {
    img: "./Images/backgrounds/numberbackgroundtablet.png",
    alt: " logistics banner with statistical highlights",
    text: "Ready to elevate your logistics experience? Connect with us now",
  },
];

export const NumberMobile = [
  {
    img: "./Images/backgrounds/numberbackgroundmobile.png",
    alt: " logistics banner with statistical highlights",
    text: "Ready to elevate your logistics experience? Connect with us now",
  },
];

export const Icons = [
  {
    img: "./Images/services/services-page/icons/exhibitions.png",
    alt: "Icon representing exhibitions",
    text: "Exhibitions",
    number: 700,
  },
  {
    img: "./Images/services/services-page/icons/delivered.png",
    alt: "Icon representing successful deliveries",
    text: "Delivered",
    number: 10000,
  },
  {
    img: "./Images/services/services-page/icons/clients.png",
    alt: "Icon representing satisfied clients",
    text: "Clients",
    number: 100,
  },
  {
    img: "./Images/services/services-page/icons/countries.png",
    alt: "Icon representing countries served",
    text: "Countries",
    number: 20,
  },
];
