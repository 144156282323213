export const sitemap = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "About",
    link: "/about",
  },
  {
    text: "Contact",
    link: "/contact",
  },
  {
    text: "Services",
    link: "/services",
  },
  {
    text: "Exhibition & Event Logistics",
    link: "/exhibition-and-event-logistics",
  },
  {
    text: "Storage & Warehousing",
    link: "/storage-and-warehousing",
  },
  {
    text: "Air Freight Forwarding",
    link: "/air-freight-forwarding",
  },
  {
    text: "Ocean Freight Forwarding ",
    link: "/ocean-freight-forwarding",
  },
  {
    text: "Gcc Trucking",
    link: "/gcc-trucking",
  },
  {
    text: "Project & Heavy Cargo handling",
    link: "/project-and-heavy-cargo-handling",
  },
  {
    text: "Awards & Recognitions",
    link: "/awards-and-recognitions",
  },
  {
    text: "News & Events",
    link: "/news-and-events",
  },
  {
    text: "Gallery",
    link: "/gallery",
  },
  {
    text: "Privacy Policy",
    link: "privacy-policy",
  },
  {
    text: "Cookie Policy",
    link: "/cookie-policy",
  },
];
