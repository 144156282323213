import React, { useState, useEffect } from "react";
import { LayoutCommon } from "../../components/layout/Layout";
import { Client } from "../../data/about";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function Clients() {
  const [columns, setColumns] = useState(4); // Default to 4 columns

  // Update the column count based on the window width
  useEffect(() => {
    const updateColumns = () => {
      if (window.innerWidth < 768) {
        setColumns(3); // Set to 3 columns if screen size is <768px (below md breakpoint)
      } else {
        setColumns(4); // Set to 4 columns for screens >=768px
      }
    };

    updateColumns(); // Call on component mount
    window.addEventListener("resize", updateColumns); // Update on resize

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  // Arrange clients into rows with the specified column count
  const rows = Client.reduce((acc, client, index) => {
    if (index % columns === 0) acc.push([]); // Start a new row based on the columns
    acc[acc.length - 1].push(client);
    return acc;
  }, []);
  return (
    <div className="bg-white pb-12">
      <LayoutCommon>
        {/** 
        <Swiper
          modules={[Autoplay]}
          spaceBetween={30} // Space between logos
          // Number of logos visible at once
          loop={true} // Infinite looping
          autoplay={{
            delay: 0, // No delay between slides
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          speed={2000} // Adjust speed for continuous feel
          centeredSlides={true} // Center items vertically
          className="flex items-center justify-center"
          breakpoints={{
            0: { slidesPerView: 3 },
            640: { slidesPerView: 3 },
            768: { slidesPerView: 4 },
            1024: { slidesPerView: 5 },
          }}
        >
          {Client.map((client, index) => (
            <SwiperSlide
              key={index}
              className="flex justify-center items-center h-full"
            >
              <img
                src={client.img}
                alt={client.alt || "Client Logo"}
                className="h-16 w-auto object-contain transition-transform duration-300 hover:scale-110"
              />
            </SwiperSlide>
          ))}
        </Swiper>*/}
        <div className="overflow-x-auto p-4">
          <table className="min-w-full">
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={rowIndex === 0 ? "" : "border-t border-black"}
                >
                  {row.map((client, colIndex) => (
                    <td
                      key={client.id}
                      className={`text-center p-4 ${
                        colIndex > 0 ? "border-l border-black" : ""
                      } ${
                        colIndex === row.length - 1
                          ? ""
                          : "border-r border-black"
                      }`}
                    >
                      <img
                        src={client.img}
                        alt={client.alt}
                        className="max-w-full h-auto object-contain transition-transform duration-300 hover:scale-110"
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </LayoutCommon>
    </div>
  );
}

export default Clients;
