import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Navbar from "../../components/navbar/Navbar";
import { Layout, LayoutCommon } from "../../components/layout/Layout";
import { about, values } from "../../data/about";
import { BannerMain } from "../services-pages/components/Banner";
import Directors from "./Directors";
import Clients from "./Clients";
import WhyChooseUs from "./WhyChooseUs";
import Footer from "../../components/footer/Footer";
import CookieBanner from "../../components/cookie/CookieBanner";
import { AboutPage } from "../../components/helmet/ReactHelmet";

gsap.registerPlugin(ScrollTrigger);
const About = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const headingRef = useRef(null);
  const descRef = useRef(null);
  const imageRef = useRef(null);
  const titleRef = useRef(null);
  const subRef = useRef(null);
  const h2Ref = useRef(null);
  const cardsRef = useRef([]);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: headingRef.current, // Trigger for the entire section
        start: "top 80%", // Start animation when 80% of the section is in view
        end: "bottom 20%", // End animation when 20% of the section is in view
        scrub: false, // Animations play without syncing to scroll
      },
    });

    // Animate heading
    tl.fromTo(
      headingRef.current,
      { opacity: 0, y: 50 }, // Start below and invisible
      {
        opacity: 1,
        y: 0, // Move to original position
        duration: 1,
        ease: "power2.out",
      }
    );

    // Animate description
    tl.fromTo(
      descRef.current,
      { opacity: 0, y: 50 }, // Start below and invisible
      {
        opacity: 1,
        y: 0, // Move to original position
        duration: 1,
        ease: "power2.out",
      },
      "+=0.2" // Delay after heading animation
    );

    // Animate image
    tl.fromTo(
      imageRef.current,
      { opacity: 0, scale: 1.2, y: 50 }, // Start zoomed in and below
      {
        opacity: 1,
        scale: 1, // Original size
        y: 0, // Original position
        duration: 1,
        ease: "power2.out",
      },
      "+=0.2" // Delay after description animation
    );

    // Animate title and subpara
    tl.fromTo(
      titleRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: "power2.out" },
      "+=0.2"
    );
    tl.fromTo(
      subRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: "power2.out" },
      "+=0.2"
    );

    // Animate h2 and cards
    tl.fromTo(
      h2Ref.current,
      { opacity: 0, y: 50 }, // Start below and invisible
      { opacity: 1, y: 0, duration: 0.8, ease: "power2.out" },
      "+=0.2"
    );

    // Animate each card individually
    cardsRef.current.forEach((card, index) => {
      tl.fromTo(
        card,
        { opacity: 0, scale: 1.2, y: -50 }, // Start zoomed in and above
        {
          opacity: 1,
          scale: 1, // Original size
          y: 0, // Original position
          duration: 0.8,
          ease: "power2.out",
        },
        "-=0.5" // Slight overlap with previous animation
      );
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let backgroundImage;
  let headingText;

  // Assuming you only have one item in the about array
  if (about.length > 0) {
    const aboutData = about[0];
    backgroundImage = screenWidth >= 768 ? aboutData.img : aboutData.imgMob;
    headingText = aboutData.heading; // Assuming heading text is in the data file
  }

  return (
    <div className="bg-bgWhite overflow-x-hidden">
      <AboutPage />
      <Navbar />
      <CookieBanner />
      {about.map((src) => (
        <div className="pt-4 sm:pt-6 md:pt-16 lg:pt-12 xl:pt-16 bg-bgWhite">
          <Layout>
            <h1
              ref={headingRef}
              className="font-arimo text-3xl sm:text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl font-semibold"
            >
              {src.head}
            </h1>
            <div className="flex flex-col lg:flex-row ">
              <div className="lg:w-3/6"></div>
              <p
                ref={descRef}
                className="mt-3 md:mt-5 xl:mt-8 lg:w-2/6 self-end"
              >
                {src.desc}
              </p>
            </div>

            <div className="mt-12 md:mt-16 xl:mt-28 md:w-[60vw] xl:w-[50vw]">
              <img
                ref={imageRef}
                src={src.mainImage}
                alt="Delivering seamless logistics solutions"
              />
            </div>
          </Layout>

          <div className="bg-skyblue -mt-64 md:-mt-72 xl:-mt-96 pb-8 md:pb-12 lg:pb-16 xl:pb-20  ">
            <Layout>
              <div className="mt-56 md:mt-72 xl:mt-96">
                <h2
                  ref={titleRef}
                  className="text-primaryBlue text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold"
                >
                  {src.subtitle}
                </h2>
                <p ref={subRef} className="mt-3 md:mt-5 lg:mt-8">
                  {src.subpara}
                </p>
              </div>
            </Layout>
          </div>
          <BannerMain />
          <div>
            <LayoutCommon>
              <h2
                ref={h2Ref}
                className="  text-primaryBlue text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold"
              >
                {src.subtitle2}
              </h2>
              <div className="text-white grid sm:grid-cols-2 md:grid-cols-3 gap-2 lg:gap-5 mt-5 md:mt-5 lg:mt-12">
                {values.map((src, index) => (
                  <div
                    key={index}
                    ref={(el) => (cardsRef.current[index] = el)}
                    className="p-8   bg-primaryBlue rounded-[32px]"
                  >
                    <h5 className=" text-base sm:text-base md:text-lg lg:text-xl 2xl:text-2xl font-semibold">
                      {src.title}
                    </h5>
                    <p className="mt-3 md:mt-4">{src.desc}</p>
                  </div>
                ))}
              </div>
            </LayoutCommon>
            <div className="relative mt-12 md:mt-16 lg:mt-28 text-white">
              <img
                src={src.team}
                alt=""
                className="rounded-[24px] shadow-lg w-full"
              />

              <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black to-transparent  opacity-100 transition-opacity duration-300 flex items-end p-5 lg:p-8">
                <div>
                  <h3 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold">
                    Team Harmony
                  </h3>
                  <p className="text-white mt-3 lg:mt-3 hidden md:block ">
                    Meet the faces behind the commitment, witness the spirit
                    defining ELF Shipping. Our vibrant team, a mix of passion
                    and expertise, works seamlessly to fulfil your logistics
                    needs. From seasoned founders to every member, each face
                    contributes to our success story.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Directors />
          <WhyChooseUs />
          <Clients />
        </div>
      ))}
      <Footer />
    </div>
  );
};

export default About;
