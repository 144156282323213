import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LayoutCommon } from "../../components/layout/Layout";
import { affliation } from "../../data/Home";

gsap.registerPlugin(ScrollTrigger);

function Affiliations() {
  const headingRef = useRef(null);
  const textRef = useRef(null);
  const logosRef = useRef([]);

  useEffect(() => {
    // GSAP Timeline for sequential animations
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: headingRef.current,
        start: "top 80%", // Trigger starts when 80% of the heading is in view
        end: "top 30%", // Ends when 30% of the heading is in view
        scrub: true, // Smooth animation on scroll
      },
    });

    // Heading animation
    tl.fromTo(
      headingRef.current,
      {
        opacity: 0, // Initially invisible
        y: 50, // Offset downwards
      },
      {
        opacity: 1, // Fully visible
        y: 0, // Moves to its original position
        duration: 1, // Duration of animation
        ease: "power2.out", // Smooth easing
      }
    );

    // Text animation
    tl.fromTo(
      textRef.current,
      {
        opacity: 0, // Initially invisible
        y: 20, // Slight downward offset
      },
      {
        opacity: 1, // Fully visible
        y: 0, // Moves to its original position
        duration: 0.8, // Duration of animation
        ease: "power2.out", // Smooth easing
      },
      "+=0.2" // Add delay after heading animation
    );

    // Logos animation (staggered)
    gsap.fromTo(
      logosRef.current,
      {
        opacity: 0, // Initially invisible
        scale: 0.8, // Slightly shrunk
        y: 20, // Offset downwards
      },
      {
        opacity: 1, // Fully visible
        scale: 1, // Original size
        y: 0, // Moves to original position
        duration: 1.2, // Duration per logo
        ease: "power2.out", // Smooth easing
        stagger: 1.2, // Stagger each logo
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 100%",
          end: "top 30%",
        },
      }
    );
  }, []);

  return (
    <div className="bg-skyblue mt-5 md:mt-8 lg:mt-16 pb-5 md:pb-8 lg:pb-16">
      <LayoutCommon>
        <h2
          ref={headingRef}
          style={{ lineHeight: "2" }}
          className="max-w-5xl text-center flex justify-center mx-auto text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold"
        >
          We are Industry recognized Experts in Freight and Logistics Services
          for Exhibitions
        </h2>
        <div className="flex flex-col flex-wrap items-center justify-center md:flex-row gap-2 md:mt-5">
          <p ref={textRef} className="flex items-center mb-2 md:mb-0">
            we’re affiliated to
          </p>
          <div className="flex flex-row flex-wrap gap-2 justify-center">
            {affliation.map((val, index) => (
              <img
                key={index}
                src={val.src}
                alt={val.alt}
                className="w-24 sm:max-w-full h-auto"
                ref={(el) => (logosRef.current[index] = el)} // Assign ref to each logo
              />
            ))}
          </div>
        </div>

        {/* Vertical Line SVG */}
      </LayoutCommon>
    </div>
  );
}

export default Affiliations;
