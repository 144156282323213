import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Review from "./Review";
import GallerySection from "./GallerySection";
import Contact from "./Contact";
import WhatsHappening from "./WhatsHappening";
import Awards from "./Awards";
import Homeservices from "./Homeservices";
import MainBenner from "./MainBenner";
import AboutHome from "./AboutHome";
import Affliations from "./Affliations";
import CookieBanner from "../../components/cookie/CookieBanner";

const Home = () => {
  return (
    <div className="font-poppins overflow-x-hidden bg-bgWhite">
      <CookieBanner />
      <Navbar />

      <MainBenner />
      <AboutHome />
      <Affliations />
      <Homeservices />
      <Awards />
      <Review />
      <WhatsHappening />
      <GallerySection />
      <Contact />
    </div>
  );
};

export default Home;
