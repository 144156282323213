import React from "react";
import { Layout } from "../../components/layout/Layout";
import { BsArrowDownCircle } from "react-icons/bs";
import AutoScrollCarousel from "./AutoScrollCarousel";
import { useEffect, useRef } from "react";
import { gsap } from "gsap";

export const images = [
  {
    src: "/Images/home/bg-clip3.png",
  },
  {
    src: "/Images/home/bg-clip1.png",
  },
  {
    src: "/Images/home/bg-clip2.png",
  },
  {
    src: "/Images/services/project/mainbanner.webp",
  },
];
function MainBenner() {
  const bannerRef = useRef(null); // Ref for the banner background
  const headingsRef = useRef([]); // Array of refs for each heading word
  const textRef = useRef(null); // Ref for the description text
  const arrowRef = useRef(null);
  const carouselRef = useRef(null);

  useEffect(() => {
    if (bannerRef.current) {
      // GSAP Timeline for the animations
      const tl = gsap.timeline();

      // Step 1: Animate the banner background
      tl.fromTo(
        bannerRef.current,
        { x: "-100%", backgroundColor: "#0a1a2f" },
        {
          x: "0%",
          backgroundColor: "#0a1a2f",
          duration: 2,
          ease: "power2.out",
        }
      );

      // Step 2: Prepare headings (opacity: 0 and y: 50)
      gsap.set(headingsRef.current, { opacity: 0, y: 50 });

      // Step 3: Animate headings sequentially
      tl.add(() => {
        gsap.to(headingsRef.current[0], {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power2.out",
        });
        gsap.to(headingsRef.current[1], {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power2.out",
          delay: 0.8,
        });
        gsap.to(headingsRef.current[2], {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power2.out",
          delay: 1.6,
        });
        gsap.to(headingsRef.current[3], {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power2.out",
          delay: 2.4,
        });
      });

      //* Step 4: Animate text opacity
      tl.to(
        textRef.current,
        {
          opacity: 1,
          duration: 1.8,
          ease: "power2.out",
        },
        "+=2.9"
      );

      // Step 5: Animate arrow opacity
      //tl.to(
      // arrowRef.current,
      // {
      //  opacity: 1,
      // duration: 0.8,
      //  ease: "power2.out",
      // },
      // "+=0.0"
      //);

      // Step 6: Animate AutoScrollCarousel (fade-up effect)
      tl.to(
        carouselRef.current,
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
        },
        "+=0.2" // Delay after arrow animation
      );
    }
  }, []);

  return (
    <div className="bg-bgWhite">
      <Layout>
        <div
          ref={bannerRef}
          className="flex  text-left items-center relative md:mt-16 lg:mt-6 bg-[#0a1a2f] w-full h-[70vh] sm:h-[75vh] md:h-[85vh] rounded-[50px]"
        >
          <div className="flex flex-col my-auto w-full h-full justify-center items-center">
            <div className="flex -mt-5 sm:mt-0 items-start flex-col text-left self-start justify-start heading">
              <div
                className="ml-8 lg:pl-5 text-5xl sm:text-6xl md:text-7xl lg:text-8xl 2xl:text-9xl font-bold text-transparent bg-clip-text"
                style={{
                  backgroundImage: `url('${images[0].src}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  animation: "moveHorizontal 3s infinite linear", // Apply the animation
                }}
                ref={(el) => (headingsRef.current[0] = el)}
              >
                EXHIBITION
              </div>

              <div
                className="ml-8 pt-4 sm:pt-8 lg:pl-5 lg:pt-8 text-5xl sm:text-6xl md:text-7xl lg:text-8xl 2xl:text-9xl font-bold text-transparent bg-clip-text"
                style={{
                  backgroundImage: `url('${images[1].src}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  animation: "moveHorizontal 3s infinite linear", // Apply the animation
                }}
                ref={(el) => (headingsRef.current[1] = el)}
              >
                LOGISTICS &
              </div>

              <div className="ml-8 gap-4 pt-4 sm:pt-8 lg:pl-5 lg:pt-8 flex flex-col md:flex-row sm:gap-8 text-5xl sm:text-6xl md:text-7xl lg:text-8xl 2xl:text-9xl font-bold">
                {/* FREIGHT */}
                <div
                  className="text-transparent bg-clip-text"
                  style={{
                    backgroundImage: `url('${images[2].src}')`, // Separate image for FREIGHT
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    animation: "moveHorizontal 3s infinite linear", // Apply animation
                  }}
                  ref={(el) => (headingsRef.current[2] = el)}
                >
                  FREIGHT
                </div>

                {/* EXPERTS */}
                <div
                  className="text-transparent bg-clip-text"
                  style={{
                    backgroundImage: `url('${images[3].src}')`, // Separate image for EXPERTS
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    animation: "moveHorizontal 3s infinite linear", // Apply animation
                  }}
                  ref={(el) => (headingsRef.current[3] = el)}
                >
                  EXPERTS
                </div>
              </div>
            </div>
            {/* Positioned absolute to bottom right */}
            <div className="mb-4 sm:mb-4 ml-8 sm:w-[40vw] lg:mr-12 lg:mb-8 2xl:mb-16 lg:w-[40vw] 2xl:w-[20vw] absolute bottom-0 right-0   mr-4 flex flex-col justify-end items-end">
              <p ref={textRef} className="self-end text-gray-400 opacity-0">
                We specialize in delivering top-tier logistics solutions
                tailored to the unique demands of the exhibition industry.
              </p>
              {/*<i
                ref={arrowRef}
                className="text-gray-400 hidden  md:block sm:text-7xl  w-[23vw] mb-5 lg:w-[16vw] 2xl:w-[12vw] xl:mb-12 mt-5 cursor-pointer hover:text-gray-200 opacity-0"
              >
                <div className="">
                  <BsArrowDownCircle />
                </div>
              </i>*/}
            </div>
          </div>
        </div>
        <div
          ref={carouselRef}
          className="opacity-0 transform translate-y-10" // Start hidden and slightly below
        >
          <AutoScrollCarousel />
        </div>
      </Layout>
    </div>
  );
}

export default MainBenner;
