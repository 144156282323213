import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import { Layout } from "../../components/layout/Layout";
import { featuredImages, gallery } from "../../data/gallery";

function FeaturedImages() {
  const images = featuredImages;

  return (
    <div className="bg-graymedium -mt-16 pb-5 xl:pb-10 md:-mt-48">
      <Layout>
        <h2 className="mt-5 md:mt-48 text-primaryBlue text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold">
          Featured Images
        </h2>
        <div className="relative flex items-center">
          {images && (
            <>
              {/* Custom positioned navigation buttons to the left of the slider */}

              {/* Swiper slider with padding-left to start after arrows */}
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                breakpoints={{
                  0: {
                    slidesPerView: 1.4,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 25,
                  },
                  1260: {
                    slidesPerView: 2.6,
                    spaceBetween: 30,
                  },
                }}
                pagination={{ clickable: true }}
                navigation={{
                  nextEl: ".custom-swiper-button-next",
                  prevEl: ".custom-swiper-button-prev",
                }}
                autoplay={{ delay: 1500, disableOnInteraction: false }}
                loop={true}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper  " // Add padding-left to create space for the arrows
                onSwiper={(swiper) => {
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
              >
                {images.map((image, idx) => (
                  <SwiperSlide
                    key={idx}
                    className="rounded-lg lg:rounded-lg my-4 md:my-6 lg:my-8"
                  >
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="rounded-lg lg:rounded-lg drop-shadow-xl w-full"
                    />
                    <div className="absolute bottom-0 w-full h-full  flex items-end p-5 lg:p-8"></div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          )}
          {/* Hide pagination dots */}
          <style>{`
            .swiper-pagination {
              display: none;
            }
          `}</style>
        </div>
        <p className="mt-1 md:mt-2 xl:mt-1  text-blacklight md:w-[60vw] 2xl:w-[40vw]">
          Each snapshot captures the precision, expertise, and commitment that
          define our services. Explore the world of seamless journeys and
          reliable partnerships through these impactful visuals.
        </p>
      </Layout>
    </div>
  );
}

export default FeaturedImages;
