export const Banner = [
  {
    head: "Air Freight Forwarding",
    img: "./Images/services/air-freight/mainbanner.webp",
    alt: "Main banner featuring an airplane in flight symbolizing air freight services",

    decsription:
      "Efficient and Expedited Air Freight Forwarding Solutions tailored for swift global deliveries. Elevate your logistics with ELF Shipping.",
  },
];
export const Introduction = [
  {
    img: "./Images/services/air-freight/air-freight-introduction.webp",
    alt: "Introduction to ELF Shipping's air freight services with an airplane and cargo",
    headline: "Swift and Secure Air Freight Forwarding",
    desc: "Choose ELF Shipping for air freight forwarding in Dubai, where speed, safety, and personalized service meet. We ensure your cargo's journey is seamless and precise, delivering exceptional air cargo solutions with a commitment to your success. Trust us for reliable, efficient air freight in the UAE. Let us elevate your logistics experience with tailored solutions that exceed expectations.",
  },
];

export const Choose = [
  {
    head: "Why Choose ELF Shipping for Your Air Freight Forwarding Needs?",
    cards: [
      {
        img: "./Images/services/air-freight/global-reach.webp",
        alt: "Global reach in air freight logistics with an airplane and world map",
        title: "Global Reach, Local Expertise",
        desc: "Benefit from our extensive network and local expertise in air freight forwarding. We seamlessly navigate international regulations and logistics to ensure your cargo reaches its destination with speed and precision.",
      },
      {
        img: "./Images/services/air-freight/time-critical.webp",
        alt: "Time-critical air freight solutions with a clock and airplane icon",
        title: "Time-Critical Solutions",
        desc: "Our air freight services prioritize time-sensitive shipments. With efficient handling and strategic partnerships, we guarantee swift delivery, meeting your deadlines and keeping your supply chain agile.",
      },
      {
        img: "./Images/services/air-freight/tailored-solutions.webp",
        alt: "Customized air freight solutions for diverse cargo types",
        title: "Tailored Solutions for Every Cargo",
        desc: "Whether it's perishables, high-value goods, or oversized cargo, our air freight forwarding services are customized to suit your specific needs. Experience flexibility and reliability in transporting diverse cargo types.",
      },
    ],
  },
];
export const Subsection = [
  {
    img: "./Images/services/air-freight/air-freight-solutions.webp",
    alt: "ELF Shipping's    Air Freight Solutions with Speed, Safety, and Personalised Service",
    headline:
      "Mastering the Skies : ELF Shipping's    Air Freight Solutions with Speed, Safety, and Personalised Service",
    desc: "ELF Shipping ensures seamless speed in delivering your cargo, meeting precise timelines through streamlined processes that prioritize efficiency without compromising safety. Rigorous measures and industry best practices guarantee secure air transportation, providing you with peace of mind. Recognizing the uniqueness of every cargo, ELF Shipping offers tailored solutions, allowing you to customize services to meet specific requirements, whether time-sensitive or requiring special handling. Our customer-centric approach ensures you receive personalized attention and support throughout the air freight forwarding process.",
  },
];
export const Client = [
  {
    name: "John",
    desc: "“Elf Shipping exceeds expectations! Their professionalism shines through every project without fail.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Simon Markose",
    desc: "“Elf Shipping's service is exceptional! They consistently provide reliable logistics solutions daily.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Aimy Aman",
    desc: "“Their leadership sets a high standard, using technology to ensure operations run very smoothly here.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Musthafa",
    desc: "“I was impressed with how Elf Shipping handled our urgent request with quick responses and great care.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Veena",
    desc: "“Fantastic experience with Elf Shipping! They made the entire process easy and stress-free for all.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Sophia",
    desc: "“The team at Elf Shipping is always ready to help! Their customer service is top-notch and responsive!”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
];
