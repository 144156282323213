import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import Navbar from "../../components/navbar/Navbar";
import { Layout } from "../../components/layout/Layout";
import Footer from "../../components/footer/Footer";

const Error = () => {
  const [animationData, setAnimationData] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    fetch("/files/ErrorPage.json")
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) =>
        console.error("Error loading Lottie animation:", error)
      );
  }, []);

  const handleHomeClick = () => {
    // Navigates to the home page when the "Go to Home" button is clicked
    navigate("/");
  };

  const handleContactClick = () => {
    // Navigates to the contact page when the "Contact Us" button is clicked
    navigate("/contact");
  };

  if (!animationData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 text-center flex flex-col">
      {/* Navbar */}
      <Navbar />
      <Layout>
        {/* Animation Section */}
        <div className="flex flex-col items-center justify-center flex-grow h-[60vh]">
          <div className="w-1/2 max-w-md max-h-62 " onClick={handleHomeClick}>
            <Lottie animationData={animationData} loop={true} />
          </div>
          {/* Page Not Found Text */}
          <div className="-mt-20 md:-mt-48 ">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-black-600">
              Page Not Found
            </h1>
            <p className="md:text-lg text-gray-700 mt-2 w-[80vw]">
              Oops! The page you are looking for doesn't exist.
            </p>
          </div>

          {/* Buttons Section */}
          <div className="mb-10 flex gap-4 mt-3 lg:mt-5 justify-center">
            <button
              onClick={handleHomeClick}
              // Trigger home page navigation
              className="bg-primaryBlue text-white border-2 font-medium border-primaryBlue hover:bg-skyblue hover:text-primaryBlue p-1 px-3 md:p-2 md:px-5 rounded-full text-base md:text-lg lg:text-xl"
            >
              Go to Home
            </button>
          </div>
        </div>
      </Layout>
      <div className="mt-12 md:mt-16 lg:mt-24 bg-bgWhite"></div>
      <Footer />
    </div>
  );
};

export default Error;
