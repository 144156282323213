import React, { useState } from "react";
import Menupage from "../../pages/menupage/Menupage";

export const MenuButton = () => {
  const [isHovered, setHovered] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleClick = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
      {isHovered ? (
        <img
          src="./Images/navbar/menu_icon_blue_hover.svg"
          alt="Second Image"
        />
      ) : (
        <img src="./Images/navbar/menu_icon_blue.svg" alt="First Image" />
      )}
      {isMenuOpen && <Menupage onClose={handleClick} />}
    </div>
  );
};
export const MenuButtonClose = ({ onClose }) => {
  const [isHovered, setHovered] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleClick = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
      {isHovered ? (
        <img
          src="./Images/navbar/menu_icon_white_hover.svg"
          alt="Second Image"
        />
      ) : (
        <img src="./Images/navbar/menu_icon_white.svg" alt="First Image" />
      )}
      {isMenuOpen && <Menupage onClick={onClose} />}
    </div>
  );
};
