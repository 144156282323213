import React from "react";
import Mainbanner from "../services-pages/components/Mainbanner";
import {
  Banner,
  Choose,
  Introduction,
  Subsection,
} from "../../data/services/airfreight";
import Intro from "./components/Intro";
import WhyChoose from "./components/WhyChoose";
import { BannerMain } from "./components/Banner";
import SubSection from "./components/SubSection";
import { NumBanner } from "./components/NumBanner";
import Clients from "./components/Clients";
import ServiceMenu from "./components/ServiceMenu";
import Footer from "../../components/footer/Footer";
import CookieBanner from "../../components/cookie/CookieBanner";
import { AirFreightPage } from "../../components/helmet/ReactHelmet";

const AirFreight = () => {
  return (
    <div className="font-poppins bg-bgWhite overflow-x-hidden">
      <AirFreightPage />
      <CookieBanner />
      <Mainbanner data={Banner} />
      <Intro data={Introduction} />
      <WhyChoose data={Choose} />
      <div className="pt-6 sm:pt-8 md:pt-12 lg:pt-16 xl:pt-20 bg-bgWhite">
        <BannerMain />
      </div>
      <SubSection data={Subsection} />
      <NumBanner />
      <Clients />

      <ServiceMenu />
      <Footer />
    </div>
  );
};

export default AirFreight;
