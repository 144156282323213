import React from "react";
import { LayoutCommon } from "../../components/layout/Layout";
import { whychoose } from "../../data/about";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import RotatingText from "../home/RotatingText";
function WhyChooseUs() {
  return (
    <div className="bg-graymedium pb-12">
      <LayoutCommon>
        <div className="flex flex-row justify-between items-center ">
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold">
            Why Choose Us
          </h2>{" "}
          <div>
            <RotatingText />
          </div>
        </div>
        <div className="block lg:hidden mt-8">
          <Swiper
            spaceBetween={16}
            slidesPerView={1.3}
            pagination={{ clickable: true }}
            breakpoints={{
              0: {
                slidesPerView: 1.4,
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              700: {
                slidesPerView: 2.5,
                spaceBetween: 30,
              },
              900: {
                slidesPerView: 3.2,
                spaceBetween: 20,
              },
            }}
          >
            {whychoose.map((item, index) => (
              <SwiperSlide key={index} className="rounded-[32px] mb-2">
                <div className="min-h-[220px] flex flex-col rounded-[32px] justify-center  p-3 bg-bgWhite   shadow-md text-center">
                  <h3 className="text-xl font-semibold mb-2">{item.head}</h3>
                  <p className="text-gray-600">{item.desc}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Grid for larger screens */}
        <div className="mt-12 hidden lg:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {whychoose.map((item, index) => (
            <div
              key={index}
              className="min-h-[250px] flex flex-col rounded-[32px] justify-center  p-3 bg-bgWhite   shadow-md text-center"
            >
              <h3 className="text-xl font-semibold mb-2">{item.head}</h3>
              <p className="text-gray-600">{item.desc}</p>
            </div>
          ))}
        </div>
      </LayoutCommon>
    </div>
  );
}

export default WhyChooseUs;
