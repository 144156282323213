import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"; // Import ScrollTrigger
import { LayoutServices } from "../../../components/layout/Layout";

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const Intro = ({ data }) => {
  const imageRefs = useRef([]); // Array of refs for images
  const headerRefs = useRef([]); // Array of refs for headings
  const paragraphRefs = useRef([]); // Array of refs for paragraphs

  useEffect(() => {
    data.forEach((item, index) => {
      const image = imageRefs.current[index];
      const header = headerRefs.current[index];
      const paragraph = paragraphRefs.current[index];

      if (image && header && paragraph) {
        // ScrollTrigger for image fade-in
        gsap.fromTo(
          image,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1.5,
            ease: "power2.out",
            scrollTrigger: {
              trigger: image,
              start: "top 80%", // Start when the image is 80% from the top of the viewport
              end: "top 30%", // End when the image reaches 30% of the viewport
              scrub: 1, // Makes animation sync with scroll
            },
          }
        );

        // ScrollTrigger for right-side content animation
        gsap.fromTo(
          header,
          { opacity: 0, y: 50 }, // Use y-axis to move the header from below
          {
            opacity: 1,
            y: 0,
            duration: 1.2,
            ease: "power2.out",
            scrollTrigger: {
              trigger: header,
              start: "top 80%", // Start when the header is 80% from the top of the viewport
              end: "top 30%", // End when the header reaches 30% of the viewport
              scrub: 1,
            },
          }
        );

        gsap.fromTo(
          paragraph,
          { opacity: 0, y: 50 }, // Use y-axis to move the paragraph from below
          {
            opacity: 1,
            y: 0,
            duration: 1.2,
            ease: "power2.out",
            scrollTrigger: {
              trigger: paragraph,
              start: "top 80%", // Start when the paragraph is 80% from the top of the viewport
              end: "top 30%", // End when the paragraph reaches 30% of the viewport
              scrub: 1,
            },
          }
        );
      }
    });
  }, [data]);

  return (
    <div className="bg-bgWhite overflow-hidden">
      {" "}
      {/* Prevent horizontal overflow */}
      <LayoutServices>
        {data.map((item, index) => (
          <div
            key={index}
            className="flex flex-col-reverse lg:flex-row lg:gap-8 items-center justify-between w-full overflow-hidden"
          >
            {/* Ensure container doesn't overflow */}
            <div className="lg:w-1/2 overflow-hidden">
              <img
                src={item.img}
                alt={item.alt}
                className="mt-5 lg:mt-0 w-full 2xl:mt-2"
                ref={(el) => (imageRefs.current[index] = el)} // Assign ref to image
              />
            </div>

            {/* Text/Content section */}
            <div className="lg:w-1/2 lg:pl-4 overflow-hidden">
              <h2
                className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-poppins font-semibold leading-normal sm:leading-tight md:leading-snug lg:leading-[2.8rem]"
                ref={(el) => (headerRefs.current[index] = el)} // Assign ref to heading
              >
                {item.headline}
              </h2>
              <p
                className="max-w-2xl 2xl:w-[35vw] font-poppins my-3 sm:my-3 lg:my-4 2xl:mt-5 font-regular text-sm md:text-base lg:text-lg"
                ref={(el) => (paragraphRefs.current[index] = el)} // Assign ref to paragraph
              >
                {item.desc}
              </p>
            </div>
          </div>
        ))}
      </LayoutServices>
    </div>
  );
};

export default Intro;
