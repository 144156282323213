export const awards = [
  {
    year: "2023",
    title: "Best Domestic On-site Agent",
    desc: "ELF Shipping is honored to receive 1st Place as Best Domestic Agent at the 2024 IELA Congress. This recognition reflects our commitment to excellence and the trust of the IELA community. We are grateful for this milestone and remain dedicated to delivering exceptional logistics solutions.",
    images: [
      {
        img: "./Images/awards/iela-2024-best-domestic-agent-1.png",
        alt: "1st Place Best Domestic Agent at 2024 IELA Congress",
      },
      {
        img: "./Images/awards/iela-2024-best-domestic-agent-2.png",
        alt: "1st Place Best Domestic Agent at 2024 IELA Congress",
      },
      {
        img: "./Images/awards/iela-2024-best-domestic-agent-3.png",
        alt: "1st Place Best Domestic Agent at 2024 IELA Congress",
      },
    ],
    alt: "1st Place Best Domestic Agent at 2024 IELA Congress",
  },
  {
    year: "2022",
    title: "Second Best Domestic On-site Agent",
    desc: "ELF Shipping proudly secured the second-place award for 'Best Domestic On-site Agent' at the 2022 IELA Congress in St. Julian's, Malta. This recognition stands as a testament to our commitment to excellence in providing top-notch logistics solutions. We are honored to be acknowledged within the industry for our outstanding on-site agent services, reinforcing our dedication to delivering exceptional results.",
    images: [
      {
        img: "./Images/awards/iela-2022-best-domestic-agent-1.png",
        alt: "Award 2022-1",
      },
      {
        img: "./Images/awards/iela-2022-best-domestic-agent-2.png",
        alt: "Award 2022-2",
      },
    ],
    alt: "second Place Best Domestic Agent at 2024 IELA Congress",
  },
  {
    year: "2019",
    title: "Best On Site Agent Award",
    desc: "In a remarkable achievement, ELF Shipping clinched the prestigious 'Best Domestic Agent 2019' award at the IELA Congress 2020 held in Bruges, Belgium. This recognition underscores our commitment to excellence and leadership in the logistics industry.",
    images: [
      {
        img: "./Images/awards/iela-2019-best-domestic-agent-1.png",
        alt: "1st Place Best Domestic Agent at 2019 IELA Congress",
      },
      {
        img: "./Images/awards/iela-2019-best-domestic-agent-2.png",
        alt: "1st Place Best Domestic Agent at 2019 IELA Congress",
      },
      {
        img: "./Images/awards/iela-2019-best-domestic-agent-3.png",
        alt: "1st Place Best Domestic Agent at 2019 IELA Congress",
      },
      {
        img: "./Images/awards/iela-2019-best-domestic-agent-4.png",
        alt: "1st Place Best Domestic Agent at 2019 IELA Congress",
      },
    ],
    alt: "1st Place Best Domestic Agent at 2019 IELA Congress",
  },
  {
    year: "2018",
    title: "Second Best Domestic On-site Agent",
    desc: " In a notable accomplishment, Elf Shipping secured the esteemed '2nd Best Domestic On-site Agent 2018' award at the IELA Congress 2019 held in Venice, Italy. This recognition highlights our dedication to providing top-notch on-site logistics solutions and our commitment to excellence in the industry.",
    images: [
      {
        img: "./Images/awards/iela-2018-best-domestic-agent-1.png",
        alt: "Second Best Domestic On-site Agent",
      },
      {
        img: "./Images/awards/iela-2018-best-domestic-agent-2.png",
        alt: "Second Best Domestic On-site Agent",
      },
    ],
    alt: "Second Best Domestic On-site Agent",
  },
  {
    year: "2016",
    title: "Best Domestic  Agent Award",
    desc: "In a momentous achievement, Elf Shipping secured the esteemed title of 'Best Domestic On-site Agent 2016' at the IELA Congress 2017, held in Prague, Czech Republic. This accolade underscores our dedication to excellence and leadership in the logistics arena.",
    images: [
      {
        img: "./Images/awards/iela-2016-best-domestic-agent-1.png",
        alt: "1st Place Best Domestic Agent at 2016 IELA Congress",
      },
      {
        img: "./Images/awards/iela-2016-best-domestic-agent-2.png",
        alt: "1st Place Best Domestic Agent at 2016 IELA Congress",
      },
    ],
    alt: "1st Place Best Domestic Agent at 2016 IELA Congress",
  },
  {
    year: "2013",
    title: "Best Domestic  Agent Award",
    desc: "In a proud moment, Elf Shipping secured the esteemed 'Best Domestic On-site Agent 2013' award at the IELA Congress 2014 in Amsterdam, Netherlands. This accolade reflects our dedication to delivering top-notch on-site logistics solutions and marks a significant milestone in our journey of excellence in the logistics industry.",
    images: [
      {
        img: "./Images/awards/iela-2013-best-domestic-agent-1.png",
        alt: "1st Place Best Domestic Agent at 2013 IELA Congress",
      },
      {
        img: "./Images/awards/iela-2013-best-domestic-agent-2.png",
        alt: "1st Place Best Domestic Agent at 2013 IELA Congress",
      },
    ],
    alt: "1st Place Best Domestic Agent at 2013 IELA Congress",
  },
];
