import React from "react";
import { useNavigate } from "react-router-dom";

const SecondaryButton = ({ path, label = "Find out more" }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
      window.scrollTo(0, 0); // Scroll to the top of the page after navigation
    }
  };

  return (
    <div>
      <button
        className="text-white bg-peach hover:text-peach hover:bg-white font-semibold
        border-2 border-peach hover:border-peach p-1 px-3 md:p-2 md:px-5 font-arimo
        rounded-full text-base md:text-lg lg:text-xl"
        onClick={handleClick}
      >
        {label}
      </button>
    </div>
  );
};

export default SecondaryButton;
