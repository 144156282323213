export const service = [
  {
    Head: "Services",
    bg: "./Images/backgrounds/Headingbg.png",
    desc: "At ELF, we offer a suite of services designed to cater to every facet of your transportation needs. From the meticulous handling of exhibitions and events to the swift and secure movement of goods through air, ocean, and GCC trucking, our commitment to excellence is unwavering. Our specialised expertise extends to Project & Heavy Cargo Handling, providing tailored solutions for oversized shipments. With state-of-the-art warehousing and storage facilities, we ensure the safety and security of your goods. Choose ELF Shipping for a seamless logistics experience where precision, reliability, and personalised service converge.    ",
    cards: [
      {
        img: "./Images/services/services-page/elf_shipping_exhibition_logistics.webp",
        alt: "Exhibition and Event Logistics Service",
        title: "Exhibition & Event Logistics ",
        desc: "Seamless logistics solutions for your exhibitions and events. From planning to execution, we ensure a successful event.",
        button: "/exhibition-and-event-logistics",
      },
      {
        img: "./Images/services/services-page/elf_shipping_warehousing.webp",
        alt: "Warehousing Services",
        title: "Storage & Warehousing",
        desc: "Secure and efficient warehousing solutions for your valuable goods. Our state-of-the-art facilities ensure optimal storage and distribution.",
        button: "/storage-and-warehousing",
      },
      {
        img: "./Images/services/services-page/elf_shipping_air_freight_service.webp",
        alt: "Air Freight Forwarding Service",
        title: "Air Freight Forwarding",
        desc: "Swift and reliable air freight solutions for your time-sensitive cargo. Our global network ensures efficient delivery to any destination.",
        button: "/air-freight-forwarding",
      },
      {
        img: "./Images/services/services-page/elf_shipping_ocean_freight_service.webp",
        alt: "Ocean Freight Forwarding Service",
        title: "Ocean Freight Forwarding",
        desc: "Seamless and cost-effective ocean freight solutions. Our expertise ensures smooth transportation across the globe.",
        button: "/ocean-freight-forwarding",
      },
      {
        img: "./Images/services/services-page/elf_shipping_gcc_trucking.webp",
        alt: "GCC Trucking Service",
        title: "GCC Trucking",
        desc: "Efficient and reliable trucking services across the GCC region. Our experienced team ensures timely delivery of your cargo.",
        button: "/gcc-trucking",
      },
      {
        img: "./Images/services/services-page/elf_shipping_project_cargo_handling.webp",
        alt: "Project and Heavy Cargo Handling Service",
        title: "Project & Heavy Cargo Handling",
        desc: "Expert handling of complex projects and heavy cargo. Our specialized solutions ensure safe and timely delivery to your destination.",
        button: "/project-and-heavy-cargo-handling",
      },
    ],
  },
];
