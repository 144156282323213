export const footer = [
  {
    logo: "./Images/logo/ELF_Shipping_LLC_logo.svg",
    alt: "ELF Shipping - Exhibition Logistics and Freight Solutions Logo",
    address: "Dubai Investment Park - 1,Dubai, UAE",
    mail: "Info@elfshipping.com ",
    contact: "04 883 4444",
    fax: "04 883 4443",
  },
];
export const menu = [
  {
    text: "About Us ",
    onclick: "/about",
  },
  {
    text: "Services ",
    onclick: "/services",
  },
  {
    text: "Contact Us ",
    onclick: "/contact",
  },
  {
    text: "News & Events ",
    onclick: "/news-and-events",
    img: "./Images/menupage/Home.png",
  },
  {
    text: "Awards & recognitions ",
    onclick: "/awards-and-recognitions",
  },
  {
    text: "Gallery ",
    onclick: "/gallery",
  },
];
export const services = [
  {
    text: "Exhibition & Event Logistics ",
    onclick: "/exhibition-and-event-logistics",
  },
  {
    text: "Air Freight  ",
    onclick: "/air-freight-forwarding",
  },
  {
    text: "Ocean Freight",
    onclick: "/ocean-freight-forwarding",
  },
  {
    text: "Heavy cargo & project handling",
    onclick: "/project-and-heavy-cargo-handling",
  },
  {
    text: "GCC Trucking ",
    onclick: "/gcc-trucking",
  },
  {
    text: "Storage and Warehousing ",
    onclick: "/storage-and-warehousing",
  },
];
