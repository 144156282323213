import React from "react";
import { useNavigate } from "react-router-dom";

function Cta() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0); // Scroll to the top of the page after navigation
  };
  return (
    <div>
      <div className="container mx-auto px-3 md:px-12 lg:px-20 xl:px-32 2xl:px-48">
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl  font-medium max-w-[80vw] sm:max-w-[60vw] lg:max-w-[40vw] ">
          Ready to elevate your logistics experience? Connect with us now, and
          let's make success together
        </h2>

        <div className="flex items-center space-x-4">
          <hr className="flex-grow border-t-2 border-gray-500" />
          <button
            className="bg-primaryBlue text-white border-2 font-medium
 border-primaryBlue hover:bg-skyblue hover:text-primaryBlue mt-2 p-1 sm:p-2 px-3 md:p-2 md:px-5   rounded-full text-base md:text-lg lg:text-xl"
            onClick={handleButtonClick}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cta;
