import React from "react";
import { LayoutCommon } from "../../components/layout/Layout";
import { review } from "../../data/Home";
import { useState, useEffect } from "react";
import Cta from "../gallery/Cta";

function Review() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true); // Track visibility for fade effect

  useEffect(() => {
    // Set interval to change the review every 5 seconds
    const interval = setInterval(() => {
      setIsVisible(false); // Start fading out

      // Wait for the fade-out animation to complete, then update the review
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % review.length);
        setIsVisible(true); // Fade-in the new review
      }, 500); // Adjust to match animation duration
    }, 4000); // Change review every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  return (
    <LayoutCommon>
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="order-2 sm:order-1 sm:w-5/12 md:w-6/12">
          <img
            src="./Images/home/Client-review.svg"
            alt="ELF Shipping - Expert Logistics and Freight Services"
          />
        </div>
        <div className="order-1 sm:order-2 sm:w-5/12 md:w-5/12 flex flex-col justify-center items-start">
          <h2 className="leading-relaxed text-primaryBlue text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold mb-3 self-start">
            What our <br /> Client’s Say
          </h2>
          <div className="relative w-full max-w-md h-40 flex items-center justify-start">
            <div
              className={`transition-opacity duration-500 ${
                isVisible ? "opacity-100" : "opacity-0"
              }`}
            >
              <p className="text-lg italic mb-4 text-blacklight">
                {review[currentIndex].desc}
              </p>
              <h5 className="font-semibold text-gray-700 text-xl">
                {review[currentIndex].name}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center">
        <div className="sm:w-3/12 md:w-2/6 lg:w-auto">
          <img
            src="./Images/home/banner.png"
            alt="Contact us for Expert Logistics and Freight Services"
          />
        </div>
        <Cta />
      </div>
    </LayoutCommon>
  );
}

export default Review;
