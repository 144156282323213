export const menu = [
  {
    text: "Home",
    onclick: "/",
    img: "./Images/menupage/elf-trucking-service.webp",
  },
  {
    text: "About Us ",
    onclick: "/about",
    img: "./Images/menupage/elf-shipping-office.webp",
  },
  {
    text: "Services ",
    onclick: "/services",
    img: "./Images/menupage/elf-shipping-services.webp",
  },

  {
    text: "News & Events ",
    onclick: "/news-and-events",
    img: "./Images/menupage/elf-news-and-events.webp",
  },
  {
    text: "Awards & recognitions ",
    onclick: "/awards-and-recognitions",
    img: "./Images/menupage/elf-shipping-awards.webp",
  },
  {
    text: "Gallery ",
    onclick: "/gallery",
    img: "./Images/menupage/elf-gallery.webp",
  },
  {
    text: "Contact Us ",
    onclick: "/contact",
    img: "./Images/menupage/elf-contact.webp",
  },
];
