import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import { contactus } from "../../data/contact";
import { Address, Contact, Fax, Mail } from "../../components/buttons/ReachUs";
import { LayoutCommon } from "../../components/layout/Layout";
import {
  Facebook,
  Instagram,
  Linkedin,
} from "../../components/social-icons/Social";
import ContactForm from "./ContactForm";
import Footer from "../../components/footer/Footer";
import CookieBanner from "../../components/cookie/CookieBanner";
import { ContactUsPage } from "../../components/helmet/ReactHelmet";
import "aos/dist/aos.css";
import AOS from "aos";
const ContactUS = () => {
  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration
      once: true, // Animate only once
    });
  }, []);
  return (
    <div className="bg-bgWhite">
      |
      <ContactUsPage />
      <Navbar />
      <CookieBanner />
      <div className="container mx-auto px-3 md-px-0 py-3 relative  ">
        <div className="mt-20 sm:mt-24 md:mt-28 lg:mt-32">
          {contactus.map((val) => (
            <>
              <div>
                <h1
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="font-poppins text-3xl sm:text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl font-semibold
              w-[45vw] sm:w-[35vw] lg:w-[40vw] xl:w-[25vw] 2xl:w-[30vw]"
                >
                  {val.head}
                </h1>
              </div>
              <p
                data-aos="fade-up"
                data-aos-delay="500"
                className="mt-3 md:mt-5 xl:mt-8 xl:w-[50vw]"
              >
                {val.desc}
              </p>
              <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-4 sm:gap-8 mt-5 sm:mt-8 lg:mt-12 xl:mt-14">
                <div>
                  <h3
                    data-aos="fade-up"
                    data-aos-delay="1000"
                    className="text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold"
                  >
                    {val.sub1}
                  </h3>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="1000"
                    className=" mt-3 sm:mt-5   xl:w-[20vw]"
                  >
                    <Address />
                  </div>
                </div>
                <div>
                  <h3
                    data-aos="fade-up"
                    data-aos-delay="1200"
                    className="text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold"
                  >
                    {val.sub2}
                  </h3>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="1300"
                    className="mt-3 sm:mt-5   xl:w-[10vw]"
                  >
                    <Contact />
                  </div>
                </div>
                <div>
                  <h3
                    data-aos="fade-up"
                    data-aos-delay="1500"
                    className="text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold"
                  >
                    {val.sub3}
                  </h3>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="1600"
                    className="sm:mt-5  mt-3  xl:w-[10vw]"
                  >
                    <Mail />
                  </div>
                </div>
              </div>
            </>
          ))}

          {/*contact form section*/}
          <LayoutCommon>
            <div
              data-aos="fade-right"
              data-aos-delay="2000"
              className="flex cursor-pointer gap-1 "
            >
              <h4 className="text-black hover:text-primaryBlue  text-3xl">
                <Linkedin />
              </h4>
              <h4 className="text-black hover:text-primaryBlue text-3xl">
                <Instagram />
              </h4>
              <h4 className="text-black hover:text-primaryBlue  text-3xl">
                <Facebook />
              </h4>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6 lg:gap-0">
              <div className="" data-aos="zoom-in-up" data-aos-delay="1500">
                <ContactForm />
              </div>
              {contactus.map((val) => (
                <div
                  className=" mx-auto md:mx-0  mt-5 md:mt-0"
                  data-aos="zoom-in-up"
                  data-aos-delay="2300"
                >
                  <img src={val.img} alt={val.alt} className="rounded-xl" />
                </div>
              ))}
            </div>
          </LayoutCommon>
        </div>
      </div>
      <div className="mt-16 sm:mt-20 md:mt-24"></div>
      <Footer />
    </div>
  );
};

export default ContactUS;
