import React, { useEffect } from "react";
import { service } from "../../data/services";
import { LayoutHead, LayoutServices } from "../../components/layout/Layout";
import Navbar from "../../components/navbar/Navbar";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import "aos/dist/aos.css";
import AOS from "aos";
import { component } from "../../data/component";
import Footer from "../../components/footer/Footer";
import Cta from "../gallery/Cta";
import { useNavigate } from "react-router-dom";
import CookieBanner from "../../components/cookie/CookieBanner";
import { HomeHelmet } from "../../components/helmet/ReactHelmet";

const Services = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration
      once: true, // Animate only once
    });
  }, []);

  return (
    <div className="bg-bgWhite">
      <HomeHelmet />
      <Navbar />
      <CookieBanner />
      <div className="container mx-auto px-3 md-px-0 py-3 relative ">
        {service.map((item) => (
          <>
            <LayoutHead>
              <div>
                <h1
                  className="font-poppins text-3xl sm:text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl font-semibold w-[70vw] lg:w-[65vw] xl:w-[60vw] 2xl:w-[50vw]"
                  data-aos="fade-up"
                >
                  {item.Head}
                </h1>
              </div>

              <p
                className="mt-3 md:mt-5 xl:mt-8 xl:w-[70vw]"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {item.desc}
              </p>
            </LayoutHead>
            <div>
              <LayoutServices>
                {item.cards.map((card, index) => (
                  <div
                    key={index}
                    className={`flex flex-col md:flex-row items-center ${
                      index % 2 === 1 ? "md:flex-row-reverse" : ""
                    } justify-between w-full space-y-4 md:space-y-0 md:space-x-12 ${
                      index === 0 ? "mt-0 mb-8" : "my-8 md:my-16"
                    }`}
                  >
                    {/* Image with zoom-in-up animation */}
                    <div
                      className="w-full md:w-1/2"
                      data-aos="zoom-in-up"
                      data-aos-delay={index * 300} // Dynamic delay for images
                    >
                      <img
                        onClick={() => {
                          navigate(card.button); // Navigate to the URL specified in the button field
                          window.scrollTo(0, 0); // Scroll to the top of the page
                        }}
                        src={card.img}
                        alt={card.alt}
                        className="rounded-[32px] w-full shadow-lg hover:shadow-2xl transition-shadow duration-300"
                      />
                    </div>

                    {/* Text/Description with fade-in animation */}
                    <div
                      className="flex flex-col items-start justify-start w-full md:w-1/2 space-y-3"
                      data-aos="fade-in"
                      data-aos-delay={index * 300 + 300} // 500ms delay after image animation
                    >
                      <h4 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold ">
                        {card.title}
                      </h4>
                      <p className="md:w-3/4 font-regular text-sm md:text-base lg:text-lg">
                        {card.desc}
                      </p>
                      <div className="flex justify-start">
                        <SecondaryButton path={card.button} />
                      </div>
                    </div>
                  </div>
                ))}
              </LayoutServices>
            </div>
            <div
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <Cta />
            </div>
          </>
        ))}
      </div>
      <div className="mt-16 sm:mt-20 md:mt-24"></div>
      <Footer />
    </div>
  );
};

export default Services;
