export const Banner = [
  {
    head: "Ocean Freight Forwarding",
    img: "./Images/services/ocean/mainbanner.webp",
    alt: "A cargo ship navigating the ocean under a clear sky, symbolizing global shipping.",

    decsription:
      "Sail with confidence. Our Ocean Freight Forwarding services ensure reliable and efficient global shipping solutions tailored to your cargo needs.",
  },
];
export const Introduction = [
  {
    img: "./Images/services/ocean/Ocean-Freight-Forwarding.webp",
    alt: "A docked cargo ship being loaded with containers, showcasing seamless logistics.",
    headline: "Sailing Beyond Expectations and Boundaries",
    desc: "Sail with ELF Shipping and explore new possibilities across the seas. Based in Dubai, UAE, our Ocean Freight Forwarding services go beyond transporting goods, delivering innovative and reliable solutions that elevate your shipping experience. Trust us to ensure your cargo's safe arrival while making your maritime journey truly exceptional.",
  },
];

export const Choose = [
  {
    head: "Why Choose ELF Shipping for Your Ocean Freight Forwarding Needs?",
    cards: [
      {
        img: "./Images/services/ocean/cost-effective.webp",
        alt: "Stacked shipping containers at a port, symbolizing cost-effective sea transport.",
        title: "Cost-Effective Sea Transport",
        desc: "Optimize your shipping costs with our ocean freight forwarding services. We offer competitive rates without compromising on the reliability and efficiency of transporting goods via sea.",
      },
      {
        img: "./Images/services/ocean/ocean-freight-versatility.webp",
        alt: "A crane loading diverse cargo onto a ship, showcasing versatility for varied shipments.",
        title: "Versatility for Varied Cargo",
        desc: "Our ocean freight services cater to a wide range of cargo types, from standard containers to oversized shipments. Enjoy the versatility to transport diverse goods securely and efficiently.",
      },
      {
        img: "./Images/services/ocean/ocean-freight-sustainability.webp",
        alt: "A vessel using sustainable technology, representing eco-friendly shipping practices.",
        title: "Sustainable Shipping Practices",
        desc: "Choose sustainability with our ocean freight solutions. We prioritize eco-friendly practices, utilizing fuel-efficient vessels and promoting environmentally conscious shipping methods for a greener supply chain.",
      },
    ],
  },
];

export const Subsection = [
  {
    img: "./Images/services/ocean/elf-shipping-ocean-freight-solutions.webp",
    alt: "A view of containers aboard a cargo ship at sea, symbolizing seamless freight solutions.",
    headline:
      "Sail Beyond Expectations with ELF Shipping's Unparalleled Ocean Freight Solutions",
    desc: "Embark on a seamless ocean freight experience with ELF Shipping's comprehensive services. Our global connectivity ensures your cargo is seamlessly integrated into major trade routes, reaching its destination with unparalleled efficiency and precision. Rest assured in the reliability of our maritime logistics, prioritizing the safety and security of your cargo. Tailored to your cargo's uniqueness, ELF Shipping provides customized ocean freight solutions, shaping our services to meet your specific shipping requirements. Experience a customer-centric approach, where you take the helm, receiving personalized attention and support, making your ocean freight journey truly exceptional.",
  },
];

export const Client = [
  {
    name: "John",
    desc: "“Elf Shipping exceeds expectations! Their professionalism shines through every project without fail.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Simon Markose",
    desc: "“Elf Shipping's service is exceptional! They consistently provide reliable logistics solutions daily.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Aimy Aman",
    desc: "“Their leadership sets a high standard, using technology to ensure operations run very smoothly here.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Musthafa",
    desc: "“I was impressed with how Elf Shipping handled our urgent request with quick responses and great care.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Veena",
    desc: "“Fantastic experience with Elf Shipping! They made the entire process easy and stress-free for all.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Sophia",
    desc: "“The team at Elf Shipping is always ready to help! Their customer service is top-notch and responsive!”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
];
