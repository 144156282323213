import React, { useEffect } from "react";
import { LayoutServices } from "../../../components/layout/Layout";
import AOS from "aos";
import "aos/dist/aos.css";
const SubSection = ({ data }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <div className="bg-bgWhite font-poppins">
      <LayoutServices>
        {data.map((item, index) => (
          <div key={index}>
            <h3
              data-aos="fade-up"
              className="text-xl sm:text-2xl md:text-3xl lg:text-4xl  font-semibold
              leading-relaxed sm:leading-snug md:leading-normal lg:leading-snug xl:leading-[3rem]"
            >
              {item.headline}
            </h3>
            <div className="border-b border-gray-500 w-full my-4"></div>
            <div className="flex flex-col md:flex-row justify-center items-center">
              <div className="w-full md:w-1/2 pr-4  " data-aos="fade-right">
                <p data-aos="zoom-in" data-aos-delay="1000">
                  {item.desc}
                </p>
              </div>
              <div
                data-aos="fade-left"
                className="w-full md:w-1/3 mt-4 md:mt-0 flex justify-center lg:pt-5 rounded-2xl  "
              >
                <img
                  src={item.img}
                  alt={item.alt}
                  className="lg:max-h-64 w-auto object-contain rounded-[32px] shadow-2xl" // Added shadow-lg for drop shadow
                  data-aos="zoom-in"
                  data-aos-delay="1500"
                />
              </div>
            </div>
          </div>
        ))}
      </LayoutServices>
    </div>
  );
};

export default SubSection;
