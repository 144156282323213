import React, { useEffect, useRef } from "react";
import { LayoutCommon } from "../../components/layout/Layout";
import ContactForm from "../contact/ContactForm";
import Footer from "../../components/footer/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Contact() {
  const headingRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    // GSAP Timeline for animations
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: headingRef.current, // Trigger starts when the left text is in view
        start: "top 80%", // When the top of the section is 80% down the viewport
        end: "bottom 50%", // When the bottom of the section is halfway in the viewport
        toggleActions: "play none none none", // Play animation once
      },
    });

    // Left-side heading animation (fade up)
    tl.fromTo(
      headingRef.current,
      {
        opacity: 0, // Initially invisible
        y: 50, // Offset downwards
      },
      {
        opacity: 1, // Fully visible
        y: 0, // Moves to its original position
        duration: 1, // Duration of animation
        ease: "power2.out", // Smooth easing
      }
    );

    // Right-side form animation (fade in from right)
    gsap.fromTo(
      formRef.current,
      {
        opacity: 0, // Initially invisible
        x: 100, // Offset to the right
      },
      {
        opacity: 1, // Fully visible
        x: 0, // Moves to its original position
        duration: 1, // Duration of animation
        ease: "power2.out", // Smooth easing
        scrollTrigger: {
          trigger: formRef.current, // Trigger starts when the form is in view
          start: "top 100%", // Adjust trigger position
          toggleActions: "play none none none", // Play animation once
        },
      }
    );
  }, []);

  return (
    <div>
      <LayoutCommon>
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/* Left Text Section */}
          <div
            ref={headingRef}
            className="text-left w-full md:w-1/2 items-center flex justify-center flex-col"
          >
            <h2 className="text-blacklight self-start text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold">
              Get in Touch{" "}
            </h2>
            <h5 className="text-gray-400 self-start text-base sm:text-lg md:text-xl lg:text-2xl 2xl:text-3xl font-semibold">
              We'd Love to Hear from You!
            </h5>
          </div>

          {/* Right Form Section */}
          <div ref={formRef} className="md:w-1/2 w-full">
            <ContactForm />
          </div>
        </div>
      </LayoutCommon>
      <div className="mt-16 sm:mt-20 md:mt-24"></div>
      <Footer />
    </div>
  );
}

export default Contact;
