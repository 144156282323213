import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";

import { Layout } from "../../components/layout/Layout";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
} from "react-icons/ai";

export const FacebookIconCircle = () => {
  const handleClick = () => {
    window.open("https://www.facebook.com/ELFShipping", "_blank");
  };

  return (
    <div
      onClick={handleClick}
      className="flex items-center justify-center w-12 h-12 border-2 border-black rounded-full transition-transform transform hover:scale-110 cursor-pointer"
    >
      <AiOutlineFacebook className="text-black-600 text-2xl" />
    </div>
  );
};
export const InstagramIconCircle = () => {
  const handleClick = () => {
    window.open("https://www.instagram.com/elfshipping/", "_blank");
  };

  return (
    <div
      onClick={handleClick}
      className="flex items-center justify-center w-12 h-12 border-2 border-black  rounded-full transition-transform transform hover:scale-110 cursor-pointer"
    >
      <AiOutlineInstagram className="text-black-600 text-2xl" />
    </div>
  );
};
export const LinkedInIconCircle = () => {
  const handleClick = () => {
    window.open("https://www.linkedin.com/company/elf-shipping-llc/", "_blank");
  };

  return (
    <div
      onClick={handleClick}
      className="flex items-center justify-center w-12 h-12 border-2 border-black rounded-full transition-transform transform hover:scale-110 cursor-pointer"
    >
      <AiOutlineLinkedin className="text-black-500 text-2xl" />
    </div>
  );
};

const SocialMedia = () => {
  useEffect(() => {
    AOS.init({ duration: 500, once: true });
  }, []);

  return (
    <div>
      <Layout>
        <h2
          className="text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold"
          data-aos="fade-up"
        >
          Follow Us
        </h2>
        <p
          className="text-center md:w-2/3 mx-auto mt-3 md:mt-5 lg:mt-6"
          data-aos="fade-up"
        >
          Stay connected and never miss an update. Follow us on our social media
          channels to stay informed about the latest news, events, and industry
          insights. Join our online community today for real-time updates and
          engaging discussions.
        </p>
        <div
          className="flex flex-row gap-5 mx-auto text-center justify-center mt-3 md:mt-5 lg:mt-6"
          data-aos="fade-up"
        >
          <FacebookIconCircle />
          <InstagramIconCircle />
          <LinkedInIconCircle />
        </div>
      </Layout>
    </div>
  );
};

export default SocialMedia;
