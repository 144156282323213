import React from "react";
import BannerButton from "../../../components/buttons/BannerButton";
import { BannerData, Bannerstorage } from "../../../data/services/banner";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { useRef, useEffect } from "react";
import { gsap } from "gsap";

gsap.registerPlugin(ScrollTrigger);

export const BannerMain = () => {
  const bannerRefs = useRef([]);

  useEffect(() => {
    // Animate the banners on scroll
    bannerRefs.current.forEach((el, index) => {
      const gradientOverlay = el.querySelector(".gradient-overlay");
      const imageElement = el.querySelector(".banner-image");
      const textContainer = el.querySelector(".text-container");

      // Create a GSAP timeline for this banner
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: "top 80%", // Start when the top of the banner hits 80% of the viewport
          end: "top 40%", // End when it reaches 40%
          scrub: 1, // Smooth animation tied to scroll
        },
      });

      // Animate image and gradient first (slower animation)
      tl.fromTo(
        [gradientOverlay, imageElement],
        { scale: 0.9, opacity: 0, x: -50 },
        {
          scale: 1,
          opacity: 1,
          x: 0,
          duration: 3, // Duration for the image and gradient animation
          ease: "power3.out",
        }
      );

      // After the image and gradient are animated, fade-in text and button (without movement)
      tl.fromTo(
        textContainer,
        { opacity: 0, y: 500 }, // Start with opacity 0
        {
          opacity: 1,
          y: 0, // End with opacity 1
          duration: 2500, // Fade-in duration (slower effect)
          ease: "power3.out", // Smooth easing for the motion
        },
        "-=1" // This makes the text fade-in after the image animation finishes
      );
    });
  }, []);

  return (
    <div className="bg-bgWhite">
      {BannerData.map((item, index) => (
        <div
          key={index}
          className="flex flex-col items-end justify-center h-72 md:h-[50vh] lg:h-[60vh] xl:h-[70vh] overflow-hidden relative"
          ref={(el) => (bannerRefs.current[index] = el)} // Attach ref to each banner
        >
          {/* Image with gradient overlay */}
          <div className="absolute inset-0 w-full h-full">
            {/* Image */}
            <img
              src={item.img}
              alt={item.alt}
              className="banner-image object-cover w-full h-full"
              style={{ zIndex: 1 }} // Ensure image is at the base
            />
            {/* Gradient Overlay */}
            <div
              className="gradient-overlay absolute inset-0"
              style={{
                background:
                  "linear-gradient(96deg, rgba(54, 76, 110, 0.48) 21.36%, #031B43 100%)",
                zIndex: 2, // Ensure gradient is above the image
              }}
            />
          </div>

          {/* Text and Button */}
          <div
            className="absolute inset-0 p-6 flex flex-col items-end justify-center text-container"
            style={{ zIndex: 3 }} // Place text and button above gradient
          >
            <div className="w-[60vw] sm:w-[40vw] xl:w-[35vw] md:me-4 lg:me-6">
              <h3 className="leading-normal sm:leading-snug md:leading-normal lg:leading-snug xl:leading-[2.5rem] max-w-xl mb-3 lg:mb-5 font-arimo text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-white">
                {item.text}
              </h3>
              <BannerButton />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export const BannerWArehouse = () => {
  return (
    <div className=" bg-bgWhite">
      {Bannerstorage.map((item) => (
        <div className="flex flex-col items-end justify-center h-72 md:h-[50vh] lg:h-[70vh] xl:h-[90vh]   overflow-hidden relative">
          <img
            src={item.img}
            alt={item.alt}
            className="object-cover w-full h-full   "
          />
          <div
            className="absolute inset-0 p-6 flex flex-col items-end justify-center"
            style={{
              background:
                "linear-gradient(96deg, rgba(54, 76, 110, 0.48) 21.36%, #031B43 100%)",
            }}
          >
            <div className="w-[60vw] sm:w-[40vw] xl:w-[35vw]  md:me-4 lg:me-6 ">
              <h3 className="  leading-normal sm:leading-snug md:leading-normal lg:leading-snug xl:leading-[2.5rem] max-w-xl mb-3 lg:mb-5 font-arimo text-xl sm:text-2xl md:text-3xl lg:text-4xl  font-semibold  text-white">
                {item.text}
              </h3>

              <BannerButton />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
