import React from "react";
import { LayoutServices } from "../../../components/layout/Layout";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";

// Import required modules from the new Swiper modules location
import { Client } from "../../../data/services/exhibition";

function Clients() {
  return (
    <div className="bg-bgWhite">
      <LayoutServices>
        <div className="clients-review-section">
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-poppins font-semibold mb-3 sm:mb-3 lg:mb-4 xl:mb-8 review-text">
            What our <span className="inline md:block">Client’s Say</span>
          </h2>

          <Swiper
            slidesPerView={1} // Show 1 review for mobile
            spaceBetween={20} // Space between reviews
            breakpoints={{
              640: { slidesPerView: 1 }, // 1 review for small screens
              768: { slidesPerView: 2 }, // 2 reviews for medium screens
              1024: { slidesPerView: 3 }, // 3 reviews for large screens (desktop)
            }}
            autoplay={{ delay: 3000, disableOnInteraction: false }} // Enable autoplay with a delay of 3 seconds
            modules={[Autoplay]} // Import Autoplay module
            className="mySwiper"
          >
            {Client.map((review) => (
              <SwiperSlide key={review.name}>
                <div className="relative flex flex-col justify-between h-48 border-none  bg-bgWhite">
                  {/* Background image */}
                  <div
                    className="absolute top-0 left-0"
                    style={{
                      backgroundImage: `url('${review.backgroundImage}')`,
                      backgroundSize: "contain", // Ensures the image is contained
                      backgroundRepeat: "no-repeat", // Prevents the image from repeating
                      width: "100px", // Adjust width as needed
                      height: "100px", // Adjust height as needed
                      zIndex: -1, // Keeps the background behind the text
                    }}
                  ></div>

                  {/* Description with reduced margin */}
                  <p className="text-left flex-grow text-gray-600">
                    {review.desc}
                  </p>
                  {/* Name with reduced padding */}
                  <p className="absolute mt-20 sm:mt-16  md:mt-28 lg:mt-28 xl:mt-28 text-left pt-1 font-arimo text-xl font-semibold text-primaryBlue">
                    {review.name}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </LayoutServices>
    </div>
  );
}

export default Clients;
