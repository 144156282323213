export const gallery = [
  {
    head: "Gallery Glimpses",
    desc: "Explore ELF Shipping's Gallery Glimpses for a snapshot of our precision, expertise, and commitment. From high-profile events to seamless cargo handling, each image tells a story of logistics excellence across air, ocean, GCC trucking, and more. Witness the impact of our services in every frame.",
    img: "./Images/Gallery/team.png",
    title: "Featured Images",
    images: [],
  },
];
export const eventGallery = [
  {
    src: "./Images/Gallery/Event/event3.png",
    alt: "ELF Shipping handling logistics for exhibition setup",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Event/event4.png",
    alt: "ELF Shipping managing cargo for trade fair exhibits",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Event/event5.png",
    alt: "ELF Shipping delivering exhibition materials to venue",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Event/event6.png",
    alt: "ELF Shipping organizing exhibition logistics efficiently",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Event/event7.png",
    alt: "ELF Shipping supporting exhibition logistics operations",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Event/event8.png",
    alt: "ELF Shipping handling transportation for exhibition cargo",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Event/event1.png",
    alt: "ELF Shipping managing logistics for exhibition success",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Event/event2.png",
    alt: "ELF Shipping ensuring timely delivery for exhibitions",
    description: "ELF Shipping Event Gallery",
  },
];

export const featuredImages = [
  {
    src: "./Images/Gallery/Team/team1.png",
    alt: "ELF Shipping team collaborating at the workplace",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/feature3.png",
    alt: "ELF Shipping providing logistics support at exhibitions",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Team/team8.png",
    alt: "ELF Shipping team working on logistics planning",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Facilities/fac1.png",
    alt: "Modern facilities at ELF Shipping's logistics hub",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Project/project1.png",
    alt: "ELF Shipping handling major logistics project",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Facilities/fac8.png",
    alt: "State-of-the-art infrastructure at ELF Shipping",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Team/feature2.png",
    alt: "ELF Shipping fostering teamwork and collaboration",
    description: "ELF Shipping Event Gallery",
  },
];
export const featuredImages2 = [
  {
    src: "./Images/Gallery/Team/team12.png",
    alt: "ELF Shipping team collaborating at the workplace",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Featured/1.png",
    alt: "ELF Shipping providing logistics support at exhibitions",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Team/team6.png",
    alt: "ELF Shipping team working on logistics planning",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Event/event2.png",
    alt: "ELF Shipping fostering teamwork and collaboration",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Facilities/fac5.png",
    alt: "Modern facilities at ELF Shipping's logistics hub",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Project/project3.png",
    alt: "ELF Shipping handling major logistics project",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Facilities/fac7.png",
    alt: "State-of-the-art infrastructure at ELF Shipping",
    description: "ELF Shipping Event Gallery",
  },
  {
    src: "./Images/Gallery/Event/event5.png",
    alt: "ELF Shipping fostering teamwork and collaboration",
    description: "ELF Shipping Event Gallery",
  },
];
export const teamGallery = [
  {
    src: "./Images/Gallery/Team/team12.png",
    alt: "ELF Shipping team collaborating during a planning session",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team3.png",
    alt: "ELF Shipping team working together on logistics projects",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team5.png",
    alt: "ELF Shipping staff engaged in brainstorming sessions",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team11.png",
    alt: "ELF Shipping team celebrating success together",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team10.png",
    alt: "ELF Shipping team members fostering a culture of excellence",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team9.png",
    alt: "Teamwork and collaboration at ELF Shipping",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team6.png",
    alt: "ELF Shipping professionals during a team-building activity",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team7.png",
    alt: "Dynamic team culture at ELF Shipping",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team2.png",
    alt: "ELF Shipping staff focusing on operational excellence",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team4.png",
    alt: "ELF Shipping team members discussing innovative solutions",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team1.png",
    alt: "ELF Shipping's dedicated team during a meeting",
    description: "ELF Shipping Team & Culture Gallery",
  },
  {
    src: "./Images/Gallery/Team/team8.png",
    alt: "Collaboration at the heart of ELF Shipping's success",
    description: "ELF Shipping Team & Culture Gallery",
  },
];

export const projectGallery = [
  {
    src: "./Images/Gallery/Project/project3.png",
    alt: "ELF Shipping managing logistics for a large-scale project",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Project/project4.png",
    alt: "Efficient cargo handling by ELF Shipping",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Project/project5.png",
    alt: "Successful infrastructure project executed by ELF Shipping",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Project/project9.png",
    alt: "ELF Shipping coordinating international freight for a key project",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Project/project8.png",
    alt: "On-site project management by ELF Shipping experts",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Project/project6.png",
    alt: "ELF Shipping supporting global supply chain solutions",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Project/project7.png",
    alt: "Specialized project logistics handled by ELF Shipping",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Project/project2.png",
    alt: "Custom logistics solutions for complex projects by ELF Shipping",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Project/project1.png",
    alt: "Innovative project logistics executed by ELF Shipping",
    description: "ELF Shipping project highlights",
  },
];

export const facilityGallery = [
  {
    src: "./Images/Gallery/Facilities/fac3.png",
    alt: "ELF Shipping's state-of-the-art warehousing facility",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Facilities/fac4.png",
    alt: "Modern infrastructure at ELF Shipping's logistics hub",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Facilities/fac5.png",
    alt: "High-tech equipment at ELF Shipping facilities",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Facilities/fac9.png",
    alt: "Strategic storage solutions by ELF Shipping",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Facilities/fac6.png",
    alt: "Efficient handling systems at ELF Shipping warehouses",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Facilities/fac7.png",
    alt: "ELF Shipping's advanced freight management facilities",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Facilities/fac2.png",
    alt: "Customizable storage spaces at ELF Shipping facilities",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Facilities/fac1.png",
    alt: "Innovative logistics infrastructure at ELF Shipping",
    description: "ELF Shipping project highlights",
  },
  {
    src: "./Images/Gallery/Facilities/fac8.png",
    alt: "ELF Shipping's global-standard logistics setup",
    description: "ELF Shipping project highlights",
  },
];
