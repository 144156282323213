export const banner = [
  {
    src: "./Images/home/services/exhibition-desk.png",
  },
];
export const abouthome = [
  {
    img: "./Images/home/Elf-Shipping-Image.png",
    alt: "ELF Shipping Office & Warehouse",
    title: "Your Premier Choice for Logistics Excellence",
    desc: "  Welcome to Elf Shipping LLC...where excellence meets reliability in the exhibition industry. Our mission is clear...to be the best and most trusted company in the business. With a strong Focus on handling Ocean and Airfreight shipments for exhibitions and Projects. We provide seamless Logistics solutions that ensures your precious cargo arrives on time and in perfect condition. Welcome to the future of Exhibition Logistics",
  },
];
export const review = [
  {
    name: "Joseph",
    desc: "“  The team at ELF Shipping was incredibly professional and efficient. I highly recommend them! ”",
  },
  {
    name: "Varun",
    desc: "“ELF Shipping made our logistics operations smooth and hassle-free. Excellent service!“",
  },
  {
    name: "Emma",
    desc: "“Their expertise in freight forwarding and cargo handling is unmatched. A reliable partner!“",
  },
];
export const homeGallery = [
  {
    head: "  Image Gallery",
    desc: "Explore our gallery of memorable moments and milestones that define our commitment to excellence. From behind-the-scenes snapshots of our dedicated team to snapshots of our successful projects and industry events, these images speak volumes about our passion for logistics.",
    descmob: "Explore our gallery of memorable moments   ",
  },
];
export const affliation = [
  {
    src: "./Images/home/affliations/NAFL.png",
    alt: "National Association of Freight and Logistics (NAFL)",
  },
  {
    src: "./Images/home/affliations/FIATA.png",
    alt: "International Federation of Freight Forwarders Associations (FIATA)",
  },
  {
    src: "./Images/home/affliations/IELA.png",
    alt: "International Exhibition Logistics Association (IELA)",
  },
];
