export const Banner = [
  {
    head: "Storage & Warehousing",
    img: "./Images/services/storage/mainbanner.webp",
    alt: "Secure, Spacious, and Tailored Warehousing Solutions at ELF Shipping.",
    decsription:
      "Secure, Spacious, and Tailored Warehousing Solutions at ELF Shipping. Trust us with the safekeeping of your goods, ensuring reliability and accessibility at every turn.",
  },
];
export const Introduction = [
  {
    img: "./Images/services/storage/modern-warehouse-facilities.webp", // Example image file
    alt: "A team managing the setup of an exhibition booth with cargo and equipment.",
    headline: "Tailored Storage Solutions for Your Needs",
    desc: "ELF Shipping specializes in cutting-edge exhibition logistics in Dubai, UAE. From transportation to setup, our expert team delivers end-to-end solutions tailored to your event needs. With precision, reliability, and excellence, we ensure seamless execution, guaranteeing your event's success. Trust us to handle your exhibits with care and professionalism.",
  },
];

export const Choose = [
  {
    head: "Why Choose ELF Shipping for Your Storage & Warehousing Needs?",
    cards: [
      {
        img: "./Images/services/storage/warehouse-facilities.webp", // Example image file
        alt: "A modern warehouse with advanced storage equipment and organized inventory.",
        title: "State-of-the-Art Facilities",
        desc: "Experience cutting-edge storage solutions with our state-of-the-art facilities. Equipped with advanced technology and infrastructure, we ensure the utmost security and efficiency for your stored goods.",
      },
      {
        img: "./Images/services/storage/customizable-storage-layout.webp", // Example image file
        alt: "Storage space with modular shelving and customizable layouts.",
        title: "Flexible and Customizable Options",
        desc: "Tailor your storage experience to meet your unique needs. Our flexible and customisable storage options provide you with the freedom to choose the solution that aligns perfectly with your business requirements.",
      },
      {
        img: "./Images/services/storage/warehouse-security-systems.webp", // Example image file
        alt: "CCTV cameras and secure access points in a warehouse environment.",
        title: "Proven Security Measures",
        desc: "Rest easy knowing that your assets are in safe hands. Our warehouses feature 24/7 monitoring, strict access control, and comprehensive security protocols, providing you with peace of mind and confidence in our storage services.",
      },
    ],
  },
];

export const Subsection = [
  {
    img: "./Images/services/storage/warehousing-expertise.webp", // Example image file
    alt: "A logistics expert reviewing inventory records in a well-organized warehouse.",
    headline:
      "Unlocking Seamless Warehousing Solutions with ELF Shipping's Operational Expertise",
    desc: "Our warehousing solutions are designed to streamline your inventory management process. ELF Shipping's state-of-the-art facilities and expert logistics team ensure that your goods are stored securely and can be accessed with ease when needed. From real-time tracking to organized storage, we provide a comprehensive solution for businesses seeking efficient warehousing. With ELF Shipping, your inventory is not just stored; it's managed with precision, giving you peace of mind and the freedom to focus on growing your business. Experience the difference with ELF Shipping.",
  },
];

export const Client = [
  {
    name: "John",
    desc: "“Elf Shipping exceeds expectations! Their professionalism shines through every project without fail.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Simon Markose",
    desc: "“Elf Shipping's service is exceptional! They consistently provide reliable logistics solutions daily.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Aimy Aman",
    desc: "“Their leadership sets a high standard, using technology to ensure operations run very smoothly here.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Musthafa",
    desc: "“I was impressed with how Elf Shipping handled our urgent request with quick responses and great care.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Veena",
    desc: "“Fantastic experience with Elf Shipping! They made the entire process easy and stress-free for all.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Sophia",
    desc: "“The team at Elf Shipping is always ready to help! Their customer service is top-notch and responsive!”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
];
