export const Banner = [
  {
    head: "Project & Heavy cargo handling",
    img: "./Images/services/project/mainbanner.webp",
    alt: "Expertly handling projects",
    decsription:
      "Expertly handling projects and heavy cargo complexities for successful outcomes. Trust ELF Shipping for tailored solutions, ensuring safety and efficiency.",
  },
];
export const Introduction = [
  {
    img: "./Images/services/project/heavy-cargo-introduction.webp",
    alt: "Introduction to ELF Shipping's heavy cargo handling services",
    headline: "Precision in Motion, Lifting Beyond Limits",
    desc: "Experience exceptional logistics with ELF Shipping, Dubai's expert in Project & Heavy Cargo Handling. From precise planning to secure execution, we specialize in transporting oversized and heavy shipments with care and efficiency. Trust ELF Shipping, UAE's leader in heavy cargo solutions, to deliver unmatched proficiency and seamless outcomes.",
  },
];

export const Choose = [
  {
    head: "Why Choose ELF Shipping for Your Project & Heavy Cargo Handling Needs?",
    cards: [
      {
        img: "./Images/services/project/specialized-expertise.webp",
        alt: "Team with expertise in heavy cargo handling",
        title: "Specialized Expertise",
        desc: "Rely on our dedicated team with specialized expertise in project and heavy cargo handling. From meticulous planning to on-site execution, we ensure seamless logistics for your unique and challenging cargo requirements.",
      },
      {
        img: "./Images/services/project/precision-execution.webp",
        alt: "Efficient heavy cargo transportation with precision",
        title: "Precision in Execution",
        desc: "Our services prioritize precision at every step. With a meticulous approach to project management and heavy cargo handling, we guarantee the safe and efficient transport of your valuable assets, minimizing risks and maximizing success.",
      },
      {
        img: "./Images/services/project/custom-solutions.webp",
        alt: "Custom solutions for complex cargo challenges",
        title: "Custom Solutions for Complex Cargo",
        desc: "Our specialized services ensure your complex cargo, whether oversized, delicate, or intricate, reaches its destination with utmost care and efficiency.",
      },
    ],
  },
];

export const Subsection = [
  {
    img: "./Images/services/project/precision-management.webp",
    alt: "ELF Shipping's expertise in managing heavy cargo",
    headline:
      "Unleash Possibilities & Elevate Logistics: ELF Shipping's Precision in Heavy Cargo Management",
    desc: "Experience ELF Shipping's seamless Project & Heavy Cargo Handling, where specialized expertise, secure transportation, and problem-solving converge. Our dedicated team ensures precision in navigating oversized shipments, prioritizing smooth transportation from planning to execution. Accommodating diverse heavy cargo types, our commitment to utmost care is unwavering. ELF Shipping's precision and reliability standards meet industry benchmarks, delivering excellence in every project with a proficient, problem-solving approach for successful outcomes.",
  },
];

export const Client = [
  {
    name: "John",
    desc: "“Elf Shipping exceeds expectations! Their professionalism shines through every project without fail.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Simon Markose",
    desc: "“Elf Shipping's service is exceptional! They consistently provide reliable logistics solutions daily.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Aimy Aman",
    desc: "“Their leadership sets a high standard, using technology to ensure operations run very smoothly here.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Musthafa",
    desc: "“I was impressed with how Elf Shipping handled our urgent request with quick responses and great care.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Veena",
    desc: "“Fantastic experience with Elf Shipping! They made the entire process easy and stress-free for all.”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
  {
    name: "Sophia",
    desc: "“The team at Elf Shipping is always ready to help! Their customer service is top-notch and responsive!”",
    backgroundImage: "./Images/backgrounds/ClientsShape.png",
  },
];
