export const contactus = [
  {
    head: "Let’s Work together",
    desc: "Connect with ELF Shipping for unparalleled logistics solutions tailored to your needs. Whether you have inquiries, require our services, or are interested in exploring a partnership, our team is ready to assist you. Your journey to seamless transportation and logistics excellence begins with ELF Shipping.",
    sub1: "Visit Us",
    sub2: "Call Us",
    sub3: "Email Us",
    img: "./Images/contact/image.png",
    alt: " Contact form with ELF Shipping's communication and customer support services",
    shape: "./Images/contact/shape.png",
  },
];
