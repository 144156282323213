import React from "react";
import { NavButton } from "../buttons/NavButton";
import { MenuButton } from "../buttons/MenuButton";

import { useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname === "/") {
      window.location.href = window.location.href; // Forces a reload
      window.scrollTo({ top: 0, behavior: "smooth" }); // Smoothly scrolls to the top
    } else {
      navigate("/");
      window.scrollTo({ top: 0, behavior: "smooth" }); // Smoothly scrolls to the top
    }
  };

  return (
    <div className="bg-bgWhite fixed top-0 left-0 w-full z-20">
      <div className="py-3 md:py-4 lg:py-4 container mx-auto px-3 md-px-0">
        <div className="flex justify-between">
          <div>
            <img
              className="w-14 h-14 cursor-pointer"
              src="./Images/logo/ELF_Shipping_LLC_logo.svg"
              alt="ElF Shipping LLC Logo"
              onClick={handleLogoClick} // Attach the click handler
            />
          </div>
          <div className="my-auto">
            <div className="flex justify-around">
              <div>
                <NavButton />
              </div>
              <div className="my-auto ms-3 md:ms-5 lg:ms-8 w-16">
                <MenuButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
