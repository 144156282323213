import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import AOS from "aos";
import "aos/dist/aos.css";
const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [status, setStatus] = useState(""); // To display success/error message

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS send function
    emailjs
      .send(
        "service_2pcrlfb", // Replace with your EmailJS service ID
        "template_4yxq23d", // Replace with your EmailJS template ID
        {
          fullName: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        },
        "PAkZNO_-KlcXsn0Fz" // Replace with your EmailJS public key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setStatus(
            "Thank you for your message! We will get back to you soon."
          );
          setFormData({ fullName: "", email: "", phone: "", message: "" }); // Reset form
        },
        (error) => {
          console.error("FAILED...", error);
          setStatus("Oops! Something went wrong. Please try again.");
        }
      );
  };

  return (
    <div className="max-w-md   mt-8 mx-auto  ">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            type="text"
            id="fullName"
            name="fullName"
            placeholder="Full Name"
            value={formData.fullName}
            onChange={handleChange}
            className="cursor-pointer mt-1 p-2 lg:p-4 lg:pl-8 pl-4 w-full border-2 border-blacklight rounded-full bg-bgWhite text-blacklight placeholder-blacklight
            focus:outline-none focus:border-primaryBlue   hover:border-primaryBlue hover:text-primaryBlue focus:text-primaryBlue "
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="cursor-pointer  mt-1 p-2 lg:p-4 lg:pl-8 pl-4 w-full border-2 border-blacklight rounded-full focus:outline-none focus:border-primaryBlue hover:border-primaryBlue hover:text-primaryBlue focus:text-primaryBlue
             bg-bgWhite text-blacklight placeholder-blacklight"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            className="mt-1 cursor-pointer  p-2 lg:p-4 lg:pl-8 pl-4 w-full border-2 border-blacklight rounded-full bg-bgWhite text-blacklight placeholder-blacklight
            focus:outline-none focus:border-primaryBlue hover:border-primaryBlue hover:text-primaryBlue focus:text-primaryBlue"
            required
          />
        </div>
        <div className="mb-4">
          <textarea
            id="message"
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            className="mt-1 cursor-pointer   p-2 lg:p-4 lg:pl-8 pl-4 w-full border-2 border-blacklight rounded-xl bg-bgWhite text-blacklight placeholder-blacklight
            focus:outline-none focus:border-primaryBlue hover:border-primaryBlue hover:text-primaryBlue focus:text-primaryBlue"
            rows="4"
          ></textarea>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="py-3 px-10 border-2 border-primaryBlue text-primaryBlue p-2 rounded-xl hover:bg-skyblue focus:outline-none focus:ring focus:border-blue-300"
          >
            Submit
          </button>
        </div>
        {status && (
          <p className="mt-4 text-center text-primaryBlue font-semibold">
            {status}
          </p>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
