import React from "react";

const RotatingText = () => {
  return (
    <div className="relative bg-primaryBlue rounded-full flex items-center justify-center w-24 h-24 md:w-32 md:h-32 mx-auto">
      {/* Center Image */}
      <img
        src="./Images/home/badge-image.svg"
        alt="center"
        className="absolute z-10  w-12  h-12 md:w-16 md:h-16 rounded-full"
      />

      {/* Rotating Text */}
      <div className="w-full h-full animate-spin-slow">
        <svg
          className="w-full h-full"
          viewBox="0 0 160 160"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <path
              id="circlePath"
              d="M 80, 80 m -55, 0 a 55,55 0 1,1 110,0 a 55,55 0 1,1 -110,0"
            />
          </defs>
          <text fontSize="19" letterSpacing="2" fill="#7C9CCA">
            {" "}
            {/* Using the specified hex color */}
            <textPath
              href="#circlePath"
              startOffset="0%"
              className="uppercase font-bold"
            >
              ELF SHIPPING • ELF SHIPPING •
            </textPath>
          </text>
        </svg>
      </div>
    </div>
  );
};

export default RotatingText;
