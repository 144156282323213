import React, { useState, useRef, useEffect } from "react";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Homeservices() {
  const images = [
    {
      src: "./Images/home/services/exhibition-desk.webp",
      alt: "ELF-Shipping-exhibition-and-event-logistics",
      link: "/exhibition-and-event-logistics",
      text: "Exhibitions & Event Logistics",
      desc: "Your Event, Perfected.",
    },
    {
      src: "./Images/home/services/warehouse-desk.webp",
      alt: "Image 2",
      link: "/storage-and-warehousing",
      text: "Storage & Warehouse",
      desc: "Your Goods, Our Care.",
    },
    {
      src: "./Images/home/services/gcc-trucking-desk.webp",
      alt: "Image 3",
      link: "/gcc-trucking",
      text: "GCC Trucking",
      desc: "Across the GCC, On Time.",
    },
    {
      src: "./Images/home/services/air-freight-desk.webp",
      alt: "Image 4",
      link: "/air-freight-forwarding",
      text: "Air Freight",
      desc: "Swift, Safe, Sky-High Service.",
    },
    {
      src: "./Images/home/services/ocean-freight-desk.webp",
      alt: "Image 5",
      link: "/ocean-freight-forwarding",
      text: "Ocean Freight",
      desc: "Seamless Shipping, Worldwide.",
    },
    {
      src: "./Images/home/services/project-handling-desk.webp",
      alt: "Image 6",
      link: "/project-and-heavy-cargo-handling",
      text: "Project & Heavy Cargo Handling",
      desc: "Big Loads, Bigger Solutions.",
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const desktopRef = useRef(null);
  const mobileRefs = useRef([]);

  useEffect(() => {
    // Desktop Animations
    gsap.fromTo(
      desktopRef.current.children,
      {
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        stagger: 0.2,
        ease: "power2.out",
        scrollTrigger: {
          trigger: desktopRef.current,
          start: "top 80%",
          end: "top 20%",
          scrub: true,
        },
      }
    );

    // Mobile Animations
    mobileRefs.current.forEach((ref, index) => {
      gsap.fromTo(
        ref,
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power2.out",
          scrollTrigger: {
            trigger: ref,
            start: "top 90%",
            end: "top 60%",
            scrub: true,
          },
        }
      );
    });
  }, []);

  return (
    <>
      {/* Desktop View */}
      <div className="lg:block hidden">
        <div
          className="flex h-[94vh] w-screen"
          ref={desktopRef} // Reference for desktop animations
        >
          {images.map((image, index) => {
            const isHovered = index === hoveredIndex;
            const width = isHovered ? "19%" : "16.66%"; // Adjust the widths as needed

            return (
              <div
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                className="relative transition-all duration-300 overflow-hidden"
                style={{ flex: `0 0 ${width}` }}
              >
                <a href={image.link} className="block h-full">
                  <img
                    src={image.src}
                    alt={image.alt}
                    className={`w-full h-full object-cover transition-transform duration-300 ${
                      isHovered ? "scale-110" : ""
                    }`}
                  />
                  {/* Overlay - covering the bottom 25% */}
                  <div
                    className={`absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-[#060A2C] to-transparent opacity-80`}
                  ></div>
                  {/* Text Container with fixed height */}
                  <div
                    className={`absolute bottom-0 left-0 w-full h-1/4 flex flex-col justify-end text-center text-white p-2`}
                  >
                    <h5 className="text-base sm:text-lg md:text-xl lg:text-2xl 2xl:text-3xl font-semibold">
                      {image.text}
                    </h5>
                    <p className="mt-1 mb-8 text-graydark text-sm">
                      {image.desc}
                    </p>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>

      {/* Mobile View */}
      <div className="block lg:hidden image-gallery w-auto">
        {images.map((image, index) => (
          <div
            key={index}
            ref={(el) => (mobileRefs.current[index] = el)} // Reference for each mobile section
          >
            <a
              href={image.link}
              className="block group w-full bg-graydark py-2"
            >
              <div className="flex flex-row justify-center mx-auto">
                <div className="w-4/12 sm:w-3/12">
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-32 h-32 p-4 object-cover transition-transform duration-300 transform group-hover:scale-105"
                  />
                </div>
                <div className="w-6/12 sm:w-5/12 text-left flex flex-col justify-center mt-2">
                  <h3 className="text-lg font-bold group-hover:text-blue-500">
                    {image.text}
                  </h3>
                  <p className="text-gray-500">{image.desc}</p>
                </div>
                <div className="w-2/12 sm:w-3/12 flex items-center justify-center">
                  <h1 className="text-2xl sm:text-3xl">
                    <BsArrowUpRightCircle />
                  </h1>
                </div>
              </div>
            </a>
            {/* Add a horizontal line between images */}
            {index < images.length - 1 && (
              <hr className="border-t border-black" />
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default Homeservices;
