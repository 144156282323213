export const cookie_policy = [
  {
    head: "Cookie Policy ",
    desc: [
      {
        sub: "Introduction",
        p: "At ELF Shipping, we are committed to protecting your privacy and ensuring that your online experience with us is transparent and secure. This Cookie Policy explains what cookies are, how we use them, and your choices regarding their use.",
      },
      {
        sub: "What Are Cookies?",
        p: "Cookies are small text files stored on your device (computer, tablet, or smartphone) by websites you visit. They help websites remember your preferences, enhance user experience, and provide insights into website performance. ",
      },
      {
        sub: "How We Use Cookies",
        p: "We use cookies to ensure our website functions properly, enabling essential features like navigation and security. Performance and analytics cookies help us understand user behavior and improve content, while functional cookies enhance your experience by remembering preferences. Additionally, targeting cookies deliver relevant ads and measure the success of our marketing campaigns. ",
      },
    ],
  },
];
export const privacy_policy = [
  {
    head: "Privacy Policy ",
    desc: [
      {
        sub: "Introduction",
        p: "At ELF Shipping, your privacy is our priority. This Privacy Policy explains how we collect, use, and protect your personal information when you visit our website or interact with our services. By using our site, you consent to the practices described in this policy. ",
      },
      {
        sub: "Information We Collect",
        p: "We collect personal information such as your name, email address, phone number, and details you provide through forms or inquiries. Additionally, we gather usage data like your IP address, browser type, device information, and browsing behavior on our site. We also use cookies to enhance your experience; for more details, refer to our Cookie Policy. ",
      },
      {
        sub: "How We Use Your Information",
        p: "We use your information to provide and improve our services, respond to inquiries, personalize your experience, analyze website usage, and comply with legal obligations. ",
      },
      {
        sub: "Sharing of Information",
        p: "We do not sell or rent your personal information. However, we may share it with service providers for specific tasks like payment processing or email delivery, or to comply with legal obligations. ",
      },
      {
        sub: "Data Security",
        p: "We employ advanced security measures to protect your personal information, but no method of data storage or transmission is entirely secure. ",
      },
      {
        sub: "Your Rights",
        p: "You have the right to access, update, delete, or withdraw consent regarding your personal data. To exercise these rights, contact us at the provided details. ",
      },
      {
        sub: "Third-Party Links",
        p: "Our website may link to external sites. We are not responsible for their privacy practices and recommend reviewing their privacy policies before sharing any information. ",
      },
      {
        sub: "Updates to This Policy",
        p: "This Privacy Policy may be updated periodically. Changes will be posted on this page, and continued use of our website constitutes acceptance of the updates. ",
      },
    ],
  },
];
