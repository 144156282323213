export const component = [
  {
    shape: "./Images/contact/shape.png",
  },
];

export const cookie = [
  {
    img: "./Images/cookie/Cookie.png",
    alt: "Cookies",
  },
];
