import React, { useEffect, useState, useRef } from "react";
import { LayoutCommon } from "../../components/layout/Layout";
import { news } from "../../data/news";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function WhatsHappening() {
  const navigate = useNavigate();
  const sortedNews = news.sort((a, b) => new Date(b.date) - new Date(a.date));
  const [scrollIndex, setScrollIndex] = useState(0);
  const [displayCount, setDisplayCount] = useState(3);
  const totalItems = sortedNews.length;
  const sectionRef = useRef(null); // Ref for the section to animate

  // Duplicate array to create infinite scrolling effect
  const infiniteNews = [...sortedNews, ...sortedNews];

  // Adjust display count based on window width
  useEffect(() => {
    const updateDisplayCount = () => {
      const width = window.innerWidth;
      if (width < 640) {
        setDisplayCount(1.3);
      } else if (width < 600) {
        setDisplayCount(2);
      } else if (width < 1024) {
        setDisplayCount(2.3);
      } else {
        setDisplayCount(3);
      }
    };

    // Initial call to set display count
    updateDisplayCount();
    // Listen for window resize events
    window.addEventListener("resize", updateDisplayCount);

    return () => window.removeEventListener("resize", updateDisplayCount);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 3000); // Scroll every 3 seconds
    return () => clearInterval(intervalId);
  }, []);

  const handleNext = () => {
    setScrollIndex((prevIndex) => (prevIndex + 1) % totalItems);
  };

  const handlePrevious = () => {
    setScrollIndex((prevIndex) =>
      prevIndex === 0 ? totalItems - 1 : prevIndex - 1
    );
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const translateX = `-${scrollIndex * (100 / displayCount)}%`;

  // GSAP Scroll Animation
  useEffect(() => {
    if (sectionRef.current) {
      gsap.fromTo(
        sectionRef.current.querySelectorAll(".news-item"),
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 100%", // Trigger animation when the section is 80% into view
          },
        }
      );
    }
  }, []);
  const handleNavigation = () => {
    window.scrollTo(0, 0); // Scroll to the top
    navigate("/news-and-events"); // Navigate to the News and Events page
  };
  return (
    <div>
      <LayoutCommon>
        <h2 className="leading-relaxed place-self-start text-left text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold">
          What's Happening
        </h2>
        <p className="mt-3 lg:mt-4">
          Stay informed about our latest news and events as we continue to
          redefine logistics standards and provide innovative solutions.
        </p>
        <div
          className="relative overflow-hidden overflow-x-hidden w-full flex box-border items-end flex-col md:flex-row md:justify-start"
          ref={sectionRef} // Ref for GSAP animation
        >
          {/* Control Buttons */}
          <div className="hidden md:flex flex-col space-y-2 md:space-y-4 mb-5 mr-2 self-end">
            <button onClick={handlePrevious} className="text-black px-4 py-2">
              <span className="text-5xl font-medium">
                <BsArrowLeftCircle />
              </span>
            </button>
            <button onClick={handleNext} className="text-black px-4">
              <span className="text-5xl font-medium">
                <BsArrowRightCircle />
              </span>
            </button>
          </div>

          {/* Carousel */}
          <div className="overflow-hidden md:ml-2 w-full">
            <div
              className="flex transition-transform duration-500"
              style={{ transform: `translateX(${translateX})` }}
            >
              {infiniteNews.map((newsItem, index) => (
                <div
                  key={index}
                  className="news-item flex-shrink-0 p-2 mt-5 lg:p-3 box-border"
                  style={{ width: `${100 / displayCount}%` }}
                  onClick={handleNavigation}
                >
                  <div className="relative text-white h-72 md:h-80 lg:h-96 xl:h-100">
                    <img
                      src={newsItem.img}
                      alt={newsItem.alt}
                      className="rounded-[24px] shadow-lg w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-black to-transparent flex items-end p-5 lg:p-8 rounded-[24px]">
                      <div>
                        <h3 className="text-lg font-semibold">
                          {newsItem.title.length > 30
                            ? `${newsItem.title.slice(0, 30)}...`
                            : newsItem.title}
                        </h3>
                        <p className="text-sm mt-1 text-peach">
                          {formatDate(newsItem.date)}
                        </p>
                        <p className="text-gray-300 text-sm mt-2">
                          {newsItem.desc.length > 70
                            ? `${newsItem.desc.slice(0, 70)}...`
                            : newsItem.desc}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </LayoutCommon>
    </div>
  );
}

export default WhatsHappening;
