import React, { useState } from "react";
import { MenuButtonClose } from "../../components/buttons/MenuButton";
import { menu } from "../../data/Menupage";
import { Social } from "../../components/social-icons/Social";
import { Link } from "react-router-dom";
const Menupage = () => {
  const [hoveredIndex, setHoveredIndex] = useState(0);

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-navyBlue  font-poppins ">
      {/**Nav part */}
      <div className="py-3 md:py-4 lg:py-4 container mx-auto px-3 md-px-0 grid justify-items-end">
        <div className="flex  flex-row justify-between my-auto text-white cursor-pointer">
          <a
            href="mailto:info@elfshipping.com"
            className="text-sm sm:text-md lg:text-xl my-auto"
          >
            info@elfshipping.com
          </a>

          <div className="mx-3 md:mx-8 lg:mx-16 my-auto">
            <a href="tel:+048834444" className="text-sm  sm:text-md lg:text-xl">
              04-883 4444
            </a>
          </div>

          <div className="my-auto h-12 pt-1 w-16">
            <MenuButtonClose />
          </div>
        </div>
      </div>
      <div className="container mx-auto px-3 md-px-0 flex items-center justify-center h-screen">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-16 -mt-72 sm:mt-0">
          <ul>
            {menu.map((item, index) => (
              <li
                className={`my-2 sm:my-3 md:my-5 lg:my-6 cursor-pointer ${
                  index !== 0 ? "lg:mb-8" : ""
                } ${index === hoveredIndex ? "text-white" : "text-graymenu"}`}
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
              >
                <Link to={item.onclick} onClick={() => window.scrollTo(0, 0)}>
                  <h4 className="text-lg md:text-xl lg:text-2xl hover:text-white">
                    {item.text}
                  </h4>
                </Link>
              </li>
            ))}
          </ul>

          <div style={{ position: "relative" }}>
            {menu.map((item, index) => (
              <div className="lg:min-w-[20rem]  md:min-h-[3rem] flex items-center justify-center">
                <img
                  key={index}
                  src={item.img}
                  alt={item.alt || ""}
                  className={`w-full h-auto 2xl:max-h-full  absolute transition-opacity duration-500 ease-in-out ${
                    index === hoveredIndex ? "opacity-100" : "opacity-0"
                  }`}
                  style={{ top: 0, left: 0 }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menupage;
