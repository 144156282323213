import React from "react";

export const LayoutCommon = ({ children }) => {
  return (
    <div className="  container pt-10 sm:pt-8 md:pt-12 lg:pt-16 xl:pt-20  mx-auto px-3 md-px-0">
      {children}
    </div>
  );
};
export const Layout = ({ children }) => {
  return (
    <div className=" container pt-20 sm:pt-24 md:pt-12 lg:pt-16 xl:pt-20  mx-auto px-3 md-px-0">
      {children}
    </div>
  );
};
export const LayoutServices = ({ children }) => {
  return (
    <div
      className="  container pt-6 sm:pt-8 md:pt-12 lg:pt-16 xl:pt-20 mx-auto px-3
 
    md:px-12 lg:px-20 xl:px-32 2xl:px-48"
    >
      {children}
    </div>
  );
};
export const LayoutHead = ({ children }) => {
  return (
    <div className="  pt-20 sm:pt-24 md:pt-24 lg:pt-28 xl:pt-32  ">
      {children}
    </div>
  );
};
