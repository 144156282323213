import React from "react";
import { useNavigate } from "react-router-dom";
const BannerButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <button
        className=" text-primaryBlue bg-white  hover:text-navyBlue  hover:bg-graydark  font-semibold
    p-1 px-3 md:p-2 md:px-5 font-arimo  rounded-full text-base md:text-lg lg:text-xl"
        onClick={handleClick}
      >
        Let's connect
      </button>
    </div>
  );
};
export default BannerButton;
