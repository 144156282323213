import React, { useEffect, useState } from "react";
import { featuredImages, featuredImages2 } from "../../data/gallery";
import "./home.css";
import { useNavigate } from "react-router-dom";
import { homeGallery } from "../../data/Home";

const GallerySection = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/gallery");
    window.scrollTo(0, 0);
  };
  return (
    <div className="bg-navyBlue mt-6 sm:mt-8 md:mt-12 lg:mt-16 xl:mt-20">
      <div className="bg-navyBlue container  text-white  mx-auto px-3 md-px-0 overflow-hidden h-[250px] sm:h-[400px] lg:h-[600px] flex space-x-4 justify-between">
        {/* Text content on the left */}
        {homeGallery.map((val) => (
          <div className="w-3/6 lg:w-2/6 flex flex-col items-center justify-center">
            <h2 className="leading-relaxed place-self-start text-left  text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold ">
              {val.head}
            </h2>
            <p className="mt-3 md:mt-5  hidden md:block">{val.desc} </p>

            <button
              className="bg-primaryBlue text-white border-2 font-medium  place-self-start mt-3 md:mt-5
 border-primaryBlue hover:bg-skyblue hover:text-primaryBlue p-1 px-3 md:p-2 md:px-5   rounded-full text-base md:text-lg lg:text-xl"
              onClick={handleClick}
            >
              View All
            </button>
          </div>
        ))}
        {/* Container for both scrolling columns aligned to the right */}
        <div className="flex flex-row space-x-2 sm:space-x-4 xl:space-x-8    w-3/6    ">
          {/* First scrolling column */}
          <div className="xl:block w-1/3 hidden"></div>
          <div className="animate-scroll flex flex-col space-y-2 sm:space-y-4 2xl:space-y-8 w-1/2  2xl:w-1/3">
            {[...featuredImages, ...featuredImages].map((image, index) => (
              <div
                key={`col1-${index}`}
                className="flex justify-center items-center h-[300px] lg:h-[250px] w-full"
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="h-auto w-auto object-cover  rounded-[8px] sm:rounded-[18px] lg:rounded-[32px]"
                />
              </div>
            ))}
          </div>

          {/* Second scrolling column with reversed order */}
          <div className="animate-scroll-reverse flex flex-col space-y-2 sm:space-y-4 2xl:space-y-8 w-1/2  2xl:w-1/3  ">
            {[...featuredImages2, ...featuredImages2]
              .reverse()
              .map((image, index) => (
                <div
                  key={`col2-${index}`}
                  className="flex justify-center items-center h-[250px] w-full"
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="h-auto w-auto object-cover rounded-[8px] sm:rounded-[18px] lg:rounded-[32px]"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection;
