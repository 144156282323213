import React, { useEffect, useRef } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { gsap } from "gsap";

const Mainbanner = ({ data }) => {
  const bannerRefs = useRef([]); // Array of refs for each banner
  const gradientRefs = useRef([]); // Array of refs for each gradient
  const contentRefs = useRef([]); // Array of refs for content wrapper
  const headerRefs = useRef([]); // Array of refs for h1
  const paragraphRefs = useRef([]); // Array of refs for paragraph
  const buttonRefs = useRef([]); // Array of refs for button

  useEffect(() => {
    bannerRefs.current.forEach((ref, index) => {
      if (
        ref &&
        gradientRefs.current[index] &&
        headerRefs.current[index] &&
        paragraphRefs.current[index] &&
        buttonRefs.current[index]
      ) {
        // Create GSAP timeline
        const tl = gsap.timeline();

        // Animate image and gradient together
        tl.fromTo(
          ref,
          { x: "-100%", clipPath: "inset(0 round 30px)" }, // Start off-screen (left) with rounded corners
          {
            x: "0%", // Move to the original position
            clipPath: "inset(0 round 30px)", // Maintain rounded corners
            duration: 2, // Adjust duration for speed
            ease: "power2.out", // Smooth easing
          }
        ).fromTo(
          gradientRefs.current[index],
          { x: "-100%", clipPath: "inset(0 round 30px)" }, // Start gradient off-screen (left) with rounded corners
          {
            x: "0%", // Move gradient to the original position
            clipPath: "inset(0 round 30px)", // Maintain rounded corners
            duration: 2, // Match duration with the image
            ease: "power2.out",
          },
          "<" // Sync with image animation
        );

        // Animate content elements (h1, p, button) sequentially
        tl.fromTo(
          headerRefs.current[index],
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 0.8, ease: "power2.out" }
        )
          .fromTo(
            paragraphRefs.current[index],
            { opacity: 0, y: 50 },
            { opacity: 1, y: 0, duration: 0.8, ease: "power2.out" },
            "-=0.4" // Slight overlap with previous animation
          )
          .fromTo(
            buttonRefs.current[index],
            { opacity: 0, y: 50 },
            { opacity: 1, y: 0, duration: 0.8, ease: "power2.out" },
            "-=0.4" // Slight overlap with previous animation
          );
      }
    });
  }, []);

  return (
    <div className="bg-bgWhite">
      <Navbar />
      {data.map((item, index) => (
        <div
          key={index}
          className="container mx-auto px-3 md:px-0 py-3 relative pt-20 sm:pt-24 md:pt-24 lg:pt-28 xl:pt-24"
        >
          <div className="relative flex items-center justify-center h-[88vh] md:h-[70vh] lg:h-[73vh] xl:h-[80vh] overflow-hidden rounded-[20px] md:rounded-[30px]">
            {/* Image */}
            <div
              className="absolute w-full h-full rounded-[20px] md:rounded-[30px]"
              ref={(el) => (bannerRefs.current[index] = el)} // Assign ref
            >
              <img
                src={item.img}
                alt={item.alt}
                className="object-cover w-full h-full rounded-[20px] md:rounded-[30px]"
              />
            </div>

            {/* Gradient overlay */}
            <div
              className="absolute inset-0 p-6 flex flex-col items-start justify-center rounded-[20px] md:rounded-[30px]"
              style={{
                background:
                  "linear-gradient(90deg, #060A2C 5.53%, rgba(0, 0, 0, 0.34) 81.71%)",
              }}
              ref={(el) => (gradientRefs.current[index] = el)} // Assign ref
            ></div>

            {/* Content */}
            <div
              className="absolute inset-0 p-6 flex flex-col items-start justify-center"
              ref={(el) => (contentRefs.current[index] = el)} // Assign ref
            >
              <div className="w-[80vw] sm:w-[50vw] xl:w-[35vw] md:ms-4 lg:ms-6">
                <h1
                  className="font-arimo text-3xl sm:text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl font-bold text-white"
                  ref={(el) => (headerRefs.current[index] = el)} // Assign ref
                >
                  {item.head}
                </h1>
                <p
                  className="font-arimo my-3 sm:my-3 lg:my-4 font-regular text-sm md:text-base lg:text-lg text-white 2xl:w-[25vw]"
                  ref={(el) => (paragraphRefs.current[index] = el)} // Assign ref
                >
                  {item.decsription}
                </p>
                <div ref={(el) => (buttonRefs.current[index] = el)}>
                  <PrimaryButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Mainbanner;
