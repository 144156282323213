import React, { useState } from "react";

import {
  BsGeoAlt,
  BsGeoAltFill,
  BsTelephone,
  BsTelephoneFill,
  BsEnvelope,
  BsEnvelopeFill,
} from "react-icons/bs";
import { RiPrinterFill, RiPrinterLine } from "react-icons/ri";

export const Address = () => {
  const [isHovered, setHovered] = useState(false);
  const handleAddressClick = () => {
    const googleMapsUrl = ` https://maps.app.goo.gl/7DpSgpSwU9sNBX7A6`;
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <div
      className="flex flex-row gap-3 cursor-pointer font-medium"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleAddressClick}
    >
      {isHovered ? (
        <i className="text-primaryBlue text-base md:text-lg lg:text-xl ">
          <BsGeoAltFill />
        </i>
      ) : (
        <i className="text-black text-base md:text-lg lg:text-xl">
          <BsGeoAlt />
        </i>
      )}
      <p className="text-black hover:text-primaryBlue font-medium">
        PLOT NO : TP071003A - National Industries Park - Dubai
      </p>
    </div>
  );
};
export const Mail = () => {
  const [isHovered, setHovered] = useState(false);
  const handleMailClick = () => {
    const emailAddress = "Info@elfshipping.com";
    const mailtoLink = `mailto:${emailAddress}`;
    window.location.href = mailtoLink;
  };
  return (
    <div
      className="flex flex-row gap-3 cursor-pointer"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleMailClick}
    >
      {isHovered ? (
        <i className="text-primaryBlue text-base md:text-lg lg:text-xl">
          <BsEnvelopeFill />
        </i>
      ) : (
        <i className="text-black text-base md:text-lg lg:text-xl">
          <BsEnvelope />
        </i>
      )}

      <p className="text-black hover:text-primaryBlue font-medium">
        Info@elfshipping.com{" "}
      </p>
    </div>
  );
};
export const Contact = () => {
  const [isHovered, setHovered] = useState(false);
  const phoneNumber = "04 883 4444";
  const phoneLink = `tel:${phoneNumber}`;

  return (
    <a
      className="flex flex-row gap-3 cursor-pointer"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      href={phoneLink}
    >
      {isHovered ? (
        <i className="text-primaryBlue text-base md:text-lg lg:text-xl">
          <BsTelephoneFill />
        </i>
      ) : (
        <i className="text-black text-base md:text-lg lg:text-xl">
          <BsTelephone />
        </i>
      )}
      <p className="text-black hover:text-primaryBlue font-medium">
        04 883 4444{" "}
      </p>
    </a>
  );
};
export const Fax = () => {
  const [isHovered, setHovered] = useState(false);
  const faxNumber = "04 883 4443"; // Replace with the actual fax number
  const faxLink = `fax:${faxNumber}`;
  return (
    <a
      className="flex flex-row gap-3 cursor-pointer"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      href={faxLink}
    >
      {isHovered ? (
        <i className="text-primaryBlue text-base md:text-lg lg:text-xl">
          <RiPrinterFill />
        </i>
      ) : (
        <i className="text-black text-base md:text-lg lg:text-xl">
          <RiPrinterLine />
        </i>
      )}
      <p className="text-black hover:text-primaryBlue font-medium">
        04 883 4443{" "}
      </p>
    </a>
  );
};
