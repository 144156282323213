import React, { useState } from "react";
import { Layout } from "../../components/layout/Layout";
import { eventGallery } from "../../data/gallery";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
function EventGallery() {
  // Keep track of the number of Swiper sliders to show (1 initially)
  const [rowsToShow, setRowsToShow] = useState(1);
  const reversedGallery = [...eventGallery].reverse();

  // Calculate the number of rows based on 3 images per row
  const galleryChunks = [];
  for (let i = 0; i < reversedGallery.length; i += 3) {
    galleryChunks.push(reversedGallery.slice(i, i + 3));
  }

  // Function to handle loading more rows
  const handleViewMore = () => {
    if (rowsToShow < galleryChunks.length) {
      setRowsToShow((prev) => prev + 1);
    }
  };

  return (
    <div className="container pt-6  md:pt-10 lg:pt-10 xl:pt-12  mx-auto px-3 md-px-0">
      <h3 className="mb-1 md:mb-3 lg:mb-8 text-blacklight text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold">
        Event Gallery
      </h3>
      <div>
        {galleryChunks.slice(0, rowsToShow).map((images, idx) => (
          <div key={idx} className=" ">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              breakpoints={{
                0: { slidesPerView: 2.3, spaceBetween: 10 },
                660: { slidesPerView: 2.5, spaceBetween: 25 },
                1020: { slidesPerView: 3, spaceBetween: 20 },
              }}
              pagination={{ clickable: true }}
              navigation={{
                nextEl: `.custom-swiper-button-next-${idx}`,
                prevEl: `.custom-swiper-button-prev-${idx}`,
              }}
              autoplay={false}
              loop={true}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper bg-bgWhite pl-16"
              onSwiper={(swiper) => {
                swiper.navigation.init();
                swiper.navigation.update();
              }}
            >
              {images.map((image, imgIdx) => (
                <SwiperSlide
                  key={imgIdx}
                  className="relative rounded-xl   my-1 sm:my-2 md:my-4 lg:my-2 bg-bgWhite overflow-hidden"
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="rounded-xl   drop-shadow-xl w-full"
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* Navigation Arrows for this Swiper instance */}
          </div>
        ))}

        {/* View More Button */}
        {rowsToShow < galleryChunks.length && (
          <div className="text-left mt-1 md:mt-3">
            <button
              onClick={handleViewMore}
              className="text-white bg-peach hover:text-peach hover:bg-white font-semibold
              border-2 border-peach hover:border-peach p-1 px-3 md:p-2 md:px-5 font-arimo
              rounded-full text-base md:text-lg lg:text-xl"
            >
              View More
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventGallery;
