import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import Exhibition from "./pages/services-pages/Exhibition";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import Services from "./pages/service/Services";
import ContactUS from "./pages/contact/Contact";
import About from "./pages/about/About";
import Awards from "./pages/awards/Awards";
import News from "./pages/news-events/News";
import Gallery from "./pages/gallery/Gallery";
import Awards1 from "./Sticky";
import Error from "./pages/error/Error";
import Storage from "./pages/services-pages/Storage";
import Ocean from "./pages/services-pages/Ocean";
import GccTrucking from "./pages/services-pages/GccTrucking";
import AirFreight from "./pages/services-pages/AirFreight";
import Project from "./pages/services-pages/Project";
import Privacy from "./pages/termsofuse/Privacy";
import Cookie from "./pages/termsofuse/Cookie";
import Sitemap from "./pages/sitemap/Sitemap";
import CookieBanner from "./components/cookie/CookieBanner";

function App() {
  return (
    <div className="bg-bgWhite">
      <Router>
        <Routes>
          <Route index element={<Home />} />

          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUS />} />
          <Route path="/services" element={<Services />} />
          <Route
            path="/exhibition-and-event-logistics"
            element={<Exhibition />}
          />
          <Route path="/storage-and-warehousing" element={<Storage />} />
          <Route path="/air-freight-forwarding" element={<AirFreight />} />
          <Route path="/ocean-freight-forwarding" element={<Ocean />} />
          <Route path="/gcc-trucking" element={<GccTrucking />} />
          <Route
            path="/project-and-heavy-cargo-handling"
            element={<Project />}
          />
          <Route path="/awards-and-recognitions" element={<Awards />} />
          <Route path="/news-and-events" element={<News />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/cookie-policy" element={<Cookie />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
