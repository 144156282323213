import React from "react";
import { Layout } from "../../components/layout/Layout";
import { cookie_policy } from "../../data/termsofuse";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import CookieBanner from "../../components/cookie/CookieBanner";
import { CookiePolicyPage } from "../../components/helmet/ReactHelmet";

function Cookie() {
  return (
    <div className="bg-bgWhite h-screen font-poppins">
      <CookiePolicyPage />
      <Navbar />
      <CookieBanner />
      {cookie_policy.map((src) => (
        <div className="pt-4 sm:pt-6 md:pt-16 lg:pt-12 xl:pt-16 bg-bgWhite">
          <Layout>
            <h1 className="font-arimo text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold">
              {src.head}
            </h1>
            {src.desc.map((val) => (
              <>
                <h3
                  className="mt-2 md:mt-3 xl:mt-5 text-md sm:text-lg md:text-xl lg:text-2xl  font-medium
              leading-relaxed sm:leading-snug md:leading-normal lg:leading-snug xl:leading-[3rem]"
                >
                  {val.sub}
                </h3>
                <p>{val.p}</p>
              </>
            ))}
          </Layout>
        </div>
      ))}
      <div className="mt-12 md:mt-16 lg:mt-24"></div>
      <Footer />
    </div>
  );
}

export default Cookie;
