import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function SeafrightCharge() {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
    });
  }, []);

  return (
    <section
      className="py-16 bg-[#0a1a2f] font-poppins text-white flex items-center"
      style={{ minHeight: "400px" }}
    >
      <div className="container mx-auto text-center">
        <h2
          className="text-4xl font-bold mb-6"
          data-aos="fade-down" // AOS animation for heading
        >
          Dubai Local Shipment Handling Charges - Sea Freight
        </h2>
        <p className="text-lg mb-6 max-w-xl mx-auto" data-aos="fade-up">
          Discover comprehensive local shipment handling charges for Sea Freight
          in Dubai. Click below to download the detailed tariff sheet.
        </p>
        {/* Button to download the PDF file */}
        <a
          href={`${process.env.PUBLIC_URL}/files/tariff.pdf`} // Updated to use process.env.PUBLIC_URL
          download="Dubai_Shipment_Handling_Charges.pdf"
          className="bg-white text-[#0a1a2f] font-medium py-2 px-4 rounded-md text-lg inline-block"
          data-aos="fade-up" // AOS animation for button
        >
          Download Tariff
        </a>
      </div>
    </section>
  );
}

export default SeafrightCharge;
