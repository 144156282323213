import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Layout } from "../../components/layout/Layout";
import { abouthome } from "../../data/Home";
import RotatingText from "./RotatingText";
import { useNavigate } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

function AboutHome() {
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const rotatingTextRef = useRef(null);
  const headingRef = useRef(null);
  const textRef = useRef(null);
  const lineRef = useRef(null);
  const buttonsRef = useRef(null);
  const statsRef = useRef(null);

  const handleClick = () => {
    window.location.href = "tel:+04 883 4444";
  };

  const handleClickButton = () => {
    navigate("/about");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // Create a timeline for sequential animations
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: imageRef.current, // Element that triggers the animation
        start: "top 80%", // Animation starts when the image enters 80% of viewport
        end: "top 30%", // Animation ends at 30% of viewport
        scrub: true, // Smooth animation during scroll
      },
    });

    // Image animation
    tl.fromTo(
      imageRef.current,
      {
        x: -100, // Initial state: off-screen to the left
        scale: 0.8, // Start slightly zoomed out
        opacity: 0, // Invisible initially
      },
      {
        x: 0, // Final state: at its original position
        scale: 1, // Full size
        opacity: 1, // Fully visible
        duration: 1.1, // Animation duration
        ease: "power2.out", // Smooth easing
      }
    );

    // RotatingText animation
    tl.fromTo(
      rotatingTextRef.current,
      {
        opacity: 0, // Initially invisible
        y: 50, // Slightly offset downward
      },
      {
        opacity: 1, // Fully visible
        y: 0, // Moves to its original position
        duration: 0.8, // Duration of fade-in animation
        ease: "power2.out", // Smooth easing
      }
    );

    // Heading animation
    tl.fromTo(
      headingRef.current,
      {
        opacity: 0, // Initially invisible
        y: 20, // Slight upward offset
      },
      {
        opacity: 1, // Fully visible
        y: 0, // Moves to its original position
        duration: 0.6, // Duration of fade-in animation
        ease: "power2.out", // Smooth easing
      }
    );

    // Text description animation
    tl.fromTo(
      textRef.current,
      {
        opacity: 0, // Initially invisible
        y: 20, // Slight upward offset
      },
      {
        opacity: 1, // Fully visible
        y: 0, // Moves to its original position
        duration: 0.6, // Duration of fade-in animation
        ease: "power2.out", // Smooth easing
      }
    );

    // Line animation
    tl.fromTo(
      lineRef.current,
      {
        scaleX: 0, // Initially shrunk horizontally
        opacity: 0, // Invisible
        transformOrigin: "left", // Expand from the left
      },
      {
        scaleX: 1, // Full width
        opacity: 1, // Fully visible
        duration: 0.5, // Duration of animation
        ease: "power2.out", // Smooth easing
      }
    );

    // Buttons animation
    tl.fromTo(
      buttonsRef.current.children,
      {
        opacity: 0, // Invisible initially
        y: 20, // Slight downward offset
      },
      {
        opacity: 1, // Fully visible
        y: 0, // Move to original position
        duration: 0.5, // Duration per button
        ease: "power2.out", // Smooth easing
        stagger: 0.2, // Staggering effect
      }
    );

    // Stats (last divs) animation
    tl.fromTo(
      statsRef.current.children,
      {
        opacity: 0, // Invisible initially
        y: 20, // Slight downward offset
      },
      {
        opacity: 1, // Fully visible
        y: 0, // Move to original position
        duration: 0.5, // Duration per stat
        ease: "power2.out", // Smooth easing
        stagger: 0.2, // Staggering effect
      }
    );
  }, []);

  return (
    <div>
      <Layout>
        {abouthome.map((val) => (
          <div className="flex flex-col lg:flex-row pb-3 md:pb-0">
            <div className="lg:w-6/12 xl:w-7/12 flex flex-row">
              <div className="relative w-full h-full">
                <img
                  src={val.img}
                  alt={val.alt}
                  ref={imageRef}
                  className="w-full h-full object-cover drop-shadow-xl rounded-[50px]"
                />
              </div>
              <div className="-ml-16 -mt-5" ref={rotatingTextRef}>
                <RotatingText />
              </div>
            </div>
            <div className="mt-8 lg:ml-16 xl:ml-0 lg:w-7/12 xl:w-5/12 flex flex-col items-center justify-center">
              <h2
                ref={headingRef}
                className="text-primaryBlue sm:-ml-20 lg:ml-0 font-poppins text-2xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold leading-normal sm:leading-tight md:leading-snug lg:leading-[2.8rem]"
              >
                {val.title}
              </h2>
              <p ref={textRef} className="mt-5 font-poppins">
                {val.desc}
              </p>
              <div
                ref={lineRef}
                className="border-t border-black w-full my-4"
              ></div>
              <div
                ref={buttonsRef}
                className="flex flex-row self-start gap-5 md:gap-5"
              >
                <button
                  className="bg-primaryBlue text-white border-2 font-medium border-primaryBlue hover:bg-skyblue hover:text-primaryBlue p-1 px-5 md:p-2 md:px-8 rounded-full text-base md:text-lg lg:text-xl"
                  onClick={handleClick}
                >
                  Call Now
                </button>
                <button
                  className="bg-white text-primaryBlue border-2 font-medium border-primaryBlue hover:bg-skyblue hover:text-primaryBlue p-1 px-3 md:p-2 md:px-5 rounded-full text-base md:text-lg lg:text-xl"
                  onClick={handleClickButton}
                >
                  Learn More
                </button>
              </div>
              <div
                ref={statsRef}
                className="flex flex-row self-start gap-5 md:gap-16 xl:gap-20 mt-5 md:mt-6"
              >
                <div className="ml-2">
                  <span className="text-center text-2xl md:text-3xl xl:text-4xl font-semibold">
                    200+{" "}
                  </span>
                  <p className="font-medium">Projects/Year</p>
                </div>
                <div>
                  <span className="text-center text-2xl md:text-3xl xl:text-4xl font-semibold">
                    100+{" "}
                  </span>
                  <p className="font-medium">Satisfied Clients</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Layout>
    </div>
  );
}

export default AboutHome;
