import React, { useEffect, useRef, useState } from "react";
import { Client } from "../../data/about";

function AutoScrollCarousel() {
  const carouselRef = useRef(null);
  const [animationDuration, setAnimationDuration] = useState("30s"); // Default duration

  useEffect(() => {
    const updateScrollSettings = () => {
      const isMobile = window.innerWidth < 740; // Check if device is mobile
      setAnimationDuration(isMobile ? "10s" : "30s"); // Adjust animation duration
    };

    // Set initial values
    updateScrollSettings();

    // Listen for window resize to dynamically update settings
    window.addEventListener("resize", updateScrollSettings);

    return () => window.removeEventListener("resize", updateScrollSettings);
  }, []);

  useEffect(() => {
    const isMobile = window.innerWidth < 640; // Check if the device is mobile
    const scrollSpeed = isMobile ? 100 : 3000; // Faster scroll for mobile

    const interval = setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.scrollBy({
          left: isMobile
            ? carouselRef.current.clientWidth / 2
            : carouselRef.current.clientWidth / 3, // Adjust scroll distance
          behavior: "smooth",
        });
      }
    }, scrollSpeed); // Interval adjusts scroll speed

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full h-[14vh] sm:h-[12vh] lg:h-[13vh] xl:h-[15vh] mt-4 rounded-[50px] bg-white drop-shadow-2xl border-2 border-blue-950 flex items-center px-4">
      <div className="overflow-hidden w-full">
        <div
          ref={carouselRef}
          className="flex w-[300%] gap-1 sm:gap-2 items-center"
          style={{
            display: "flex",
            animation: `auto-scroll ${animationDuration} linear infinite`, // Dynamic duration
          }}
        >
          {/* Duplicating images for seamless loop */}
          {Client.concat(Client).map((client, index) => (
            <div
              key={index}
              className="flex-none w-40 h-32 flex items-center mx-1 sm:mx-2"
            >
              <img
                src={client.img}
                alt={client.alt}
                className="w-full h-full object-contain rounded-lg"
              />
            </div>
          ))}
        </div>

        {/* Inline CSS for the auto-scroll animation */}
        <style jsx>{`
          @keyframes auto-scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}</style>
      </div>
    </div>
  );
}

export default AutoScrollCarousel;
