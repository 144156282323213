import React from "react";
import "./animation.css";
const Animation = () => {
  return (
    <div>
      <div className="style">
        <div className="border-b-2 border-lineblue w-full  " />

        <div class="animl   font-black">
          ELF SHIPPING. ELF SHIPPING. ELF SHIPPING. ELF SHIPPING. ELF SHIPPING.
          ELF SHIPPING.
        </div>
        <div className="border-b-2 border-lineblue w-full  " />
        <div class="animr font-black">
          ELF SHIPPING. ELF SHIPPING. ELF SHIPPING. ELF SHIPPING. ELF SHIPPING.
          ELF SHIPPING.
        </div>
        <div className="border-b-2 border-lineblue w-full  " />
      </div>
    </div>
  );
};

export default Animation;
