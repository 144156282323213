import React from "react";
import { Helmet } from "react-helmet";

export const HomeHelmet = () => {
  return (
    <div>
      <Helmet>
        <title>
          ELF Shipping LLC | Best Exhibition & Event Logistics, Freight,
          Warehouse, GCC Trucking & Shipping Solutions in Dubai, UAE
        </title>
        <meta
          name="description"
          content="ELF Shipping LLC is the best logistics provider in Dubai, UAE, offering exhibition & event logistics, freight forwarding, warehouse services, and GCC trucking. Trust us for reliable, tailored shipping and logistics solutions across the globe."
        />
        <meta
          name="keywords"
          content="Exhibition logistics, Event logistics, Freight forwarding, Warehouse services, GCC trucking, Shipping solutions, Dubai logistics, UAE logistics"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.elfshipping.com" />
      </Helmet>

      {/* Your page content */}
    </div>
  );
};

export const AboutPage = () => {
  return (
    <div>
      <Helmet>
        <title>
          About Us | ELF Shipping LLC - Exhibition & Event Logistics
        </title>
        <meta
          name="description"
          content="Learn more about ELF Shipping LLC, the leading logistics provider in Dubai, UAE, specializing in exhibition & event logistics, freight, and GCC trucking."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.elfshipping.com/about" />
      </Helmet>

      {/* About page content */}
    </div>
  );
};

export const GalleryPage = () => {
  return (
    <div>
      <Helmet>
        <title>Gallery | ELF Shipping LLC - Exhibition & Event Logistics</title>
        <meta
          name="description"
          content="Explore the gallery of ELF Shipping LLC showcasing our participation in exhibitions, events, and our logistics operations across the UAE and GCC region."
        />
        <meta
          name="keywords"
          content="Gallery, ELF Shipping, Exhibition logistics, Event logistics, Shipping, Logistics provider, Dubai logistics, UAE logistics"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.elfshipping.com/gallery" />
      </Helmet>

      {/* Gallery page content */}
    </div>
  );
};

export const NewsAndEventsPage = () => {
  return (
    <div>
      <Helmet>
        <title>
          News & Events | ELF Shipping LLC - Latest Updates and Highlights
        </title>
        <meta
          name="description"
          content="Stay up to date with the latest news and events from ELF Shipping LLC, your trusted logistics partner in Dubai, UAE. Discover our latest exhibitions, awards, and more."
        />
        <meta
          name="keywords"
          content="News, Events, ELF Shipping, Logistics news, Dubai logistics, UAE logistics, Awards, Shipping events, Exhibition highlights"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.elfshipping.com/news-and-events"
        />
      </Helmet>

      {/* News and Events page content */}
    </div>
  );
};
export const ContactUsPage = () => {
  return (
    <div>
      <Helmet>
        <title>
          Contact Us | ELF Shipping LLC - Get in Touch for Logistics Solutions
        </title>
        <meta
          name="description"
          content="Contact ELF Shipping LLC today for reliable logistics, exhibition & event logistics, freight forwarding, and GCC trucking solutions. Get in touch with our team in Dubai, UAE."
        />
        <meta
          name="keywords"
          content="Contact ELF Shipping, Logistics solutions, Freight forwarding, Event logistics, GCC trucking, Dubai logistics, UAE logistics"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.elfshipping.com/contact" />
      </Helmet>

      {/* Contact Us page content */}
    </div>
  );
};

export const AwardsPage = () => {
  return (
    <div>
      <Helmet>
        <title>
          Awards | ELF Shipping LLC - Recognized for Excellence in Logistics
        </title>
        <meta
          name="description"
          content="Discover the awards and recognitions ELF Shipping LLC has received for excellence in logistics, exhibition & event logistics, and freight services across Dubai and the UAE."
        />
        <meta
          name="keywords"
          content="Awards, ELF Shipping, Logistics excellence, Freight forwarding, Event logistics, Shipping awards, Dubai logistics, UAE logistics"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.elfshipping.com/awards-and-recognitions"
        />
      </Helmet>

      {/* Awards page content */}
    </div>
  );
};
export const ExhibitionEventLogisticsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Exhibition & Event Logistics | ELF Shipping LLC</title>
        <meta
          name="description"
          content="ELF Shipping LLC offers expert exhibition & event logistics services, ensuring smooth transportation and setup of exhibits across Dubai, UAE, and GCC."
        />
        <meta
          name="keywords"
          content="Exhibition logistics, Event logistics, Shipping, Dubai logistics, UAE logistics, GCC logistics, Event transportation, Exhibition setup"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.elfshipping.com/exhibition-and-event-logistics"
        />
      </Helmet>

      {/* Exhibition & Event Logistics page content */}
    </div>
  );
};
export const AirFreightPage = () => {
  return (
    <div>
      <Helmet>
        <title>Air Freight Services | ELF Shipping LLC</title>
        <meta
          name="description"
          content="ELF Shipping LLC provides reliable and efficient air freight services for fast and secure transportation of goods to and from Dubai, UAE, and GCC countries."
        />
        <meta
          name="keywords"
          content="Air Freight, Freight services, Air cargo, International shipping, UAE air freight, Dubai air cargo, Freight forwarding"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.elfshipping.com/air-freight-forwarding"
        />
      </Helmet>

      {/* Air Freight page content */}
    </div>
  );
};
export const OceanFreightPage = () => {
  return (
    <div>
      <Helmet>
        <title>Ocean Freight Services | ELF Shipping LLC</title>
        <meta
          name="description"
          content="ELF Shipping LLC offers comprehensive ocean freight services, ensuring timely and safe delivery of goods across global shipping routes from Dubai, UAE, and GCC."
        />
        <meta
          name="keywords"
          content="Ocean Freight, Freight forwarding, Sea cargo, Container shipping, Dubai ocean freight, UAE shipping, GCC freight"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.elfshipping.com/ocean-freight-forwarding"
        />
      </Helmet>

      {/* Ocean Freight page content */}
    </div>
  );
};
export const HeavyCargoProjectHandlingPage = () => {
  return (
    <div>
      <Helmet>
        <title>Heavy Cargo & Project Handling | ELF Shipping LLC</title>
        <meta
          name="description"
          content="ELF Shipping LLC specializes in the handling of heavy cargo and complex project shipments, offering tailored solutions for oversized and difficult-to-transport items."
        />
        <meta
          name="keywords"
          content="Heavy cargo, Project handling, Oversized shipments, Freight forwarding, Dubai heavy cargo, UAE logistics, GCC cargo handling"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.elfshipping.com/project-and-heavy-cargo-handling"
        />
      </Helmet>

      {/* Heavy Cargo & Project Handling page content */}
    </div>
  );
};
export const StorageWarehousingPage = () => {
  return (
    <div>
      <Helmet>
        <title>Storage & Warehousing Services | ELF Shipping LLC</title>
        <meta
          name="description"
          content="ELF Shipping LLC offers state-of-the-art storage and warehousing solutions, providing secure facilities for your goods in Dubai, UAE, and GCC."
        />
        <meta
          name="keywords"
          content="Storage services, Warehousing, Logistics solutions, Dubai warehousing, UAE storage, Freight logistics, Secure storage"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.elfshipping.com/storage-and-warehousing"
        />
      </Helmet>

      {/* Storage and Warehousing page content */}
    </div>
  );
};
export const GccTruckingPage = () => {
  return (
    <div>
      <Helmet>
        <title>GCC Trucking Services | ELF Shipping LLC</title>
        <meta
          name="description"
          content="ELF Shipping LLC provides reliable GCC trucking services, ensuring efficient and timely transportation of goods across the Gulf Cooperation Council (GCC) countries including UAE, Saudi Arabia, Oman, Qatar, Bahrain, and Kuwait."
        />
        <meta
          name="keywords"
          content="GCC trucking, Freight services, Truck transportation, Dubai trucking, UAE trucking, GCC logistics, International shipping, GCC freight forwarding"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.elfshipping.com/gcc-trucking" />
      </Helmet>

      {/* GCC Trucking page content */}
    </div>
  );
};
export const SitemapPage = () => {
  return (
    <div>
      <Helmet>
        <title>Sitemap | ELF Shipping LLC</title>
        <meta
          name="description"
          content="Explore the complete sitemap of ELF Shipping LLC, a leading logistics provider in Dubai, UAE. Easily find links to all our services, pages, and sections."
        />
        <meta
          name="keywords"
          content="Sitemap, ELF Shipping, Logistics company, Dubai logistics, UAE logistics, GCC shipping, Services, Freight services, Exhibition logistics"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.elfshipping.com/sitemap" />
      </Helmet>

      {/* Sitemap page content */}
    </div>
  );
};

export const PrivacyPolicyPage = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy | ELF Shipping LLC</title>
        <meta
          name="description"
          content="Read the privacy policy of ELF Shipping LLC. Learn about how we protect your personal data and information when using our website and services."
        />
        <meta
          name="keywords"
          content="Privacy Policy, ELF Shipping, Data protection, Personal information, Website privacy, UAE privacy policy, Dubai privacy policy"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.elfshipping.com/privacy-policy"
        />
      </Helmet>

      {/* Privacy Policy page content */}
    </div>
  );
};
export const CookiePolicyPage = () => {
  return (
    <div>
      <Helmet>
        <title>Cookie Policy | ELF Shipping LLC</title>
        <meta
          name="description"
          content="Read ELF Shipping LLC's cookie policy. Learn about how we use cookies to enhance your experience and improve our website functionality."
        />
        <meta
          name="keywords"
          content="Cookie Policy, ELF Shipping, Cookies, Website cookies, Privacy, Data collection, UAE cookie policy, Dubai cookie policy"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.elfshipping.com/cookie-policy"
        />
      </Helmet>

      {/* Cookie Policy page content */}
    </div>
  );
};
